import { Fragment, useEffect } from 'react'
import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import Loader from '../../../common/loader/Loader'
import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/24/outline'
import CreateMeetingType from '../create-form-popup/CreateMeetingType'
import { selectOpenForm, setOpenForm } from '../../../../reducers/types-and-templates/meeting-type/create-type'
import {
  selectAllTypes,
  reset,
  selectRequestStatus,
  changeRequestStatus,
} from '../../../../reducers/types-and-templates/meeting-type/type-list'
import { getAllTypes } from './slice'
import Breadcrumbs from '../../../common/breadcrumbs/Breadcrumbs'
import { selectTemplateDetails } from '../../../../reducers/types-and-templates/meeting-type-templates/template-details'

export default function MeetingTypeList() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const types = useSelector(selectAllTypes)
  const template = useSelector(selectTemplateDetails)
  const { templateUuid } = useParams()

  const pages = [
    { name: 'Meeting type templates', href: '/meeting-type-templates', current: false },
    { name: template?.name, href: `/meeting-type-templates/${templateUuid}`, current: false },
    { name: 'Meeting types', href: `/meeting-type-templates/${templateUuid}/meeting-types`, current: true },
  ]

  const openNewMeetingTypeForm = useSelector(selectOpenForm)
  const setOpenNewMeetingTypeForm = (status) => {
    dispatch(setOpenForm({ openForm: status }))
  }

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchTypes = dispatch(getAllTypes({ templateUuid }))

    return () => {
      dispatch(reset())
      fetchTypes.abort()
    }
  }, [])

  return (
    <>
      <div className="sticky z-40 flex h-16 shrink-0 items-center gap-x-6 border-b bg-white border-black/5 px-4 sm:px-6 lg:px-8">
        <div>
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      <div className="py-4 px-4 sm:px-6 lg:px-8">
        <div className={'flex flex-row justify-between items-center'}>
          <div className="flex flex-row items-center px-4 sm:px-0">
            <NavLink
              className={'p-4 rounded-md bg-gray-50 hover:bg-indigo-50 mr-6 cursor-pointer'}
              to={`/meeting-type-templates/${templateUuid}`}
            >
              <ArrowLeftIcon className={'w-5 h-5'} />
            </NavLink>
            <div>
              <h3 className="text-base font-semibold leading-7 text-gray-900">Meeting types</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                All meeting types related to the selected template
              </p>
            </div>
          </div>
          <button
            type="button"
            onClick={() => {
              setOpenNewMeetingTypeForm(true)
            }}
            className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Create new meeting type
          </button>
        </div>

        {types === null && requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
        {types !== null && requestStatus === REQUEST_STATUSES.DONE && (
          <>
            <div className={'pt-8'}>
              {types.length === 0 && <div className={'text-sm text-gray-700'}>There are no meeting types yet.</div>}
              {types.length > 0 && (
                <div className="py-4 space-y-4">
                  {types.map((type) => (
                    <NavLink
                      to={`/meeting-type-templates/${templateUuid}/meeting-types/${type.uuid}`}
                      key={`meeting-type-${type.uuid}`}
                      className={
                        'bg-gray-50 rounded-md p-6 flex flex-col justify-between hover:bg-indigo-50 transition-all'
                      }
                    >
                      <div>
                        <div className={'text-sm font-semibold text-gray-700'}>{type.name}</div>
                        <div className={'text-xs font-normal pt-2 text-gray-500'}>{type.description}</div>
                      </div>
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
        <div className="z-30">
          <CreateMeetingType open={openNewMeetingTypeForm} setOpen={setOpenNewMeetingTypeForm} />
        </div>
      </div>
    </>
  )
}
