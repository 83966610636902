import React, { memo } from 'react'
import { Outlet } from 'react-router-dom'
import SettingsSidebar from './SettingsSidebar'
import MainLayout from '../../common/protected/main-layout/MainLayout'

function UserSettings() {
  return (
    <MainLayout>
      <div className="py-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl lg:flex lg:gap-x-16 lg:px-8">
            <SettingsSidebar />

            <main className="px-4 sm:px-6 lg:flex-auto lg:px-0">
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default memo(UserSettings)
