import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeAgent } from '../../../../api/meetingTypeAgent'

export const createNewMeetingType = createAsyncThunk(
  'meeting-types-v2/create-new-meeting-type',
  async ({ name, description, templateUuid, context, variables, workspaceUuid }, thunkApi) => {
    try {
      const type = await MeetingTypeAgent.createNewMeetingTypeV2(
        name,
        description,
        templateUuid,
        context,
        variables,
        workspaceUuid,
      )

      return { type }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
