import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminUserAgent } from '../../../api/adminUserAgent'

export const signIn = createAsyncThunk('admin-user/account/sign-in', async ({ email, password }, thunkApi) => {
  try {
    const response = await AdminUserAgent.signIn(email, password)

    return response
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})
