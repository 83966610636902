import React, { memo } from 'react'
import { ReactSVG } from 'react-svg'
import Logo from '../../../assets/images/am-logo.svg'

function SingleFormWrapper({ children }) {
  return (
    <>
      <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex justify-center items-center">
            <ReactSVG
              src={Logo}
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 122px; height: 32px;')
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">{children}</div>
    </>
  )
}

export default memo(SingleFormWrapper)
