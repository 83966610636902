import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeTemplateAgent } from '../../../../api/meetingTypeTemplateAgent'

export const getAllTemplates = createAsyncThunk(
  'meeting-type-templates/get-all-templates',
  async (undefined, thunkApi) => {
    try {
      const templates = await MeetingTypeTemplateAgent.getAllMeetingTypeTemplates()

      return { templates }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
