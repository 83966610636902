import { Fragment, useEffect, useState } from 'react'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { ChevronRightIcon, HomeIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { deleteType, getTypeDetails } from './slice'
import { selectTypeDetails, typeUnloaded } from '../../../../reducers/meetings/types/details'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import CreateTag from './tags/create/CreateTag'
import UpdateType from '../update/UpdateType'
import {
  selectOpenForm as selectOpenTypeUpdateForm,
  setOpenForm as setOpenTypeUpdateForm,
} from '../../../../reducers/meetings/types/update'
import {
  selectOpenForm as selectOpenNewTagForm,
  setOpenForm as setOpenNewTagForm,
} from '../../../../reducers/meetings/types/tags/create'
import {
  selectOpenForm as selectOpenUpdateTagForm,
  setOpenForm as setOpenUpdateTagForm,
} from '../../../../reducers/meetings/types/tags/update'
import UpdateTag from './tags/update/UpdateTag'

export default function TypeDetails() {
  const dispatch = useDispatch()

  const { meetingTypeUuid } = useParams()

  const type = useSelector(selectTypeDetails)

  const [activeTagUuid, setActiveTagUuid] = useState(null)

  const openUpdateTypeForm = useSelector(selectOpenTypeUpdateForm)
  const setOpenUpdateTypeFormProcess = (status) => {
    dispatch(setOpenTypeUpdateForm({ openForm: status }))
  }

  const openNewTagForm = useSelector(selectOpenNewTagForm)
  const setOpenNewTagFormProcess = (status) => {
    dispatch(setOpenNewTagForm({ openForm: status }))
  }

  const openUpdateTagForm = useSelector(selectOpenUpdateTagForm)
  const setOpenUpdateTagFormProcess = (uuid) => {
    dispatch(setOpenUpdateTagForm({ openForm: uuid }))
  }

  const deleteProcess = () => {
    dispatch(deleteType({ uuid: meetingTypeUuid }))
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const pages = [
    { name: 'Types', href: '/meetings/types', current: false },
    { name: type ? type.name : 'Loading...', href: '#', current: true },
  ]

  useEffect(() => {
    const fetchType = dispatch(getTypeDetails({ uuid: meetingTypeUuid }))

    return () => {
      dispatch(typeUnloaded())
      fetchType.abort()
    }
  }, [])

  return (
    <>
      <div className="sticky top-16 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b bg-white border-black/5 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div>
                  <Link to="/meetings/types" className="text-gray-400 hover:text-gray-500">
                    <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className="flex items-center">
                    <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <Link
                      to={page.href}
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      aria-current={page.current ? 'page' : undefined}
                    >
                      {page.name}
                    </Link>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <Menu as="div" className="relative flex-none">
            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setOpenUpdateTypeFormProcess(true)
                      }}
                      className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                      )}
                    >
                      Update
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={deleteProcess}
                      className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900',
                      )}
                    >
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {type && (
        <div className="py-4 px-4 sm:px-6 lg:px-8">
          <div>
            <div className="px-4 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Meeting Type Information</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Meeting type details and prompts</p>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Name</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{type.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Meta prompt</dt>
                  <dd className="mt-1 text-sm leading-6 whitespace-pre-line text-gray-700 sm:col-span-2 sm:mt-0">
                    {type.metaPrompt}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">User prompt</dt>
                  <dd className="mt-1 text-sm leading-6 whitespace-pre-line text-gray-700 sm:col-span-2 sm:mt-0">
                    {type.userPrompt}
                  </dd>
                </div>
                {type.accountUuid && (
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Account uuid</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{type.accountUuid}</dd>
                  </div>
                )}
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    <p>Tags</p>
                  </dt>
                  <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <div className="flex border-b border-gray-100 pb-4 items-center justify-between gap-x-6">
                      <button
                        type="button"
                        onClick={() => setOpenNewTagFormProcess(true)}
                        className="mt-4 relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Create new tag
                      </button>
                    </div>
                    <ul role="list" className="divide-y divide-gray-100 ">
                      {type.tags &&
                        type.tags.map((tag) => (
                          <li key={tag.uuid}>
                            <div
                              onClick={() => setActiveTagUuid(tag.uuid)}
                              className="flex items-start justify-between gap-x-6 py-5"
                            >
                              <div className="min-w-0">
                                <div className="flex items-start gap-x-3">
                                  <p className="text-sm font-semibold leading-6 text-gray-900">{tag.name}</p>
                                </div>
                                <div className="mt-2 flex items-center whitespace-pre-line gap-x-2 text-sm leading-5 text-gray-400">
                                  {tag.description}
                                </div>
                                <div className="mt-4 p-4 bg-black/5 flex items-center whitespace-pre-line gap-x-2 text-sm leading-5 text-gray-500">
                                  {tag.prompt}
                                </div>
                              </div>
                              <div className="flex flex-none items-center gap-x-4">
                                <Menu as="div" className="relative flex-none">
                                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                    <span className="sr-only">Open options</span>
                                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            onClick={() => {
                                              setOpenUpdateTagFormProcess(tag.uuid)
                                            }}
                                            className={classNames(
                                              active ? 'bg-gray-50' : '',
                                              'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                                            )}
                                          >
                                            Update
                                          </button>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <Link
                                            href="#"
                                            className={classNames(
                                              active ? 'bg-gray-50' : '',
                                              'block px-3 py-1 text-sm leading-6 text-gray-900',
                                            )}
                                          >
                                            Delete
                                          </Link>
                                        )}
                                      </Menu.Item>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      )}
      <CreateTag open={openNewTagForm} setOpen={setOpenNewTagFormProcess} />
      <UpdateType open={openUpdateTypeForm} setOpen={setOpenUpdateTypeFormProcess} />
      <UpdateTag open={openUpdateTagForm} setOpen={setOpenUpdateTagFormProcess} />
    </>
  )
}
