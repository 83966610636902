import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../../reducer-helper'
import { getTemplateDetails } from '../../../components/types-and-templates/template/details/slice'
import { updateTemplate } from '../../../components/types-and-templates/template/update-form-popup/slice'

const initialState = {
  data: {
    uuid: '',
    name: '',
    description: '',
    systemTasks: '',
    isPopular: null,
    metaPrompt: '',
    userPrompt: '',
    workspaceUuid: '',
  },
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'meetingTemplateDetails',
  initialState,
  extraReducers(builder) {
    builder.addCase(getTemplateDetails.fulfilled, (state, action) => {
      state.data = action.payload.template
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(updateTemplate.fulfilled, (state, action) => {
      state.data = action.payload.template
    })
    builder.addCase(getTemplateDetails.rejected, failed)
  },
  reducers: {
    reset: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.meetingTemplateDetails
export const selectTemplateDetails = (state) => selectSlice(state).data
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, reset } = actions

export default reducer
