import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { clearRedirect } from '../../reducers/common'
import agent from '../../api/agent'
import { initApplication } from '../app/slice'

export default function Root() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const redirectTo = useSelector((state) => state.common.redirectTo)
  const appLoaded = useSelector((state) => state.common.appLoaded)

  useEffect(() => {
    if (redirectTo) {
      navigate(redirectTo)
      dispatch(clearRedirect())
    }
  }, [redirectTo])

  useEffect(() => {
    const token = window.localStorage.getItem('admin-api-token')
    agent.setApiToken(token)
    dispatch(initApplication())
  }, [])

  if (appLoaded) {
    return <Outlet />
  } else {
    return <div>Loading</div>
  }
}
