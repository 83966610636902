import { createSlice } from '@reduxjs/toolkit'
import { createNewMeetingType } from '../../../components/types-and-templates/type/create-form-popup/slice'

const initialState = {
  data: {
    name: '',
    description: '',
    context: '',
    variables: [],
    workspaceUuid: '',
  },
  openForm: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'createMeetingType',
  initialState,
  extraReducers(builder) {
    builder.addCase(createNewMeetingType.fulfilled, () => initialState)
  },
  reducers: {
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changeDescription: (state, action) => {
      state.data.description = action.payload.description
    },
    changeContext: (state, action) => {
      state.data.context = action.payload.context
    },
    addVariable: (state, action) => {
      state.data.variables.push({ name: '', value: '' })
    },
    deleteVariable: (state, action) => {
      state.data.variables.splice(action.payload.indexForDelete, 1)
    },
    changeVariable: (state, action) => {
      state.data.variables[action.payload.index] = action.payload.variable
    },
    changeWorkspaceUuid: (state, action) => {
      state.data.workspaceUuid = action.payload.workspaceUuid
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload.openForm
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.createMeetingType
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectOpenForm = (state) => selectSlice(state).openForm
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const {
  changeRequestStatus,
  resetForm,
  changeName,
  changeDescription,
  changeContext,
  addVariable,
  deleteVariable,
  changeVariable,
  changeWorkspaceUuid,
  setOpenForm,
} = actions

export default reducer
