import React, { useEffect } from 'react'
import MainLayout from '../common/protected/main-layout/MainLayout'
import { Outlet, useParams } from 'react-router-dom'
import { getMeetingTypeDetails } from './type/details/slice'
import {
  reset as resetTypeDetails,
  changeRequestStatus as changeGetTypeRequestStatus,
} from '../../reducers/types-and-templates/meeting-type/type-details'
import { useDispatch } from 'react-redux'
import { REQUEST_STATUSES } from '../../reducers/reducer-helper'
import {
  changeRequestStatus as changeGetTemplateRequestStatus,
  reset as resetTemplateDetails,
} from '../../reducers/types-and-templates/meeting-type-templates/template-details'
import {
  changeRequestStatus as changeGetTaskRequestStatus,
  reset as resetTaskDetails,
} from '../../reducers/types-and-templates/meeting-type-task/task-details'
import { getTemplateDetails } from './template/details/slice'
import { getTaskDetails } from './tasks/details/slice'

export default function TypesAndTemplates() {
  const dispatch = useDispatch()

  const { templateUuid, typeUuid, taskUuid } = useParams()

  useEffect(() => {
    if (templateUuid) {
      dispatch(changeGetTemplateRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      const fetchTemplate = dispatch(getTemplateDetails({ uuid: templateUuid }))

      return () => {
        dispatch(resetTemplateDetails())
        fetchTemplate.abort()
      }
    }
  }, [templateUuid])

  useEffect(() => {
    if (typeUuid) {
      dispatch(changeGetTypeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      const fetchType = dispatch(getMeetingTypeDetails({ uuid: typeUuid }))

      return () => {
        dispatch(resetTypeDetails())
        fetchType.abort()
      }
    }
  }, [typeUuid])

  useEffect(() => {
    if (taskUuid) {
      dispatch(changeGetTaskRequestStatus({ status: REQUEST_STATUSES.PENDING }))
      const fetchTask = dispatch(getTaskDetails({ taskUuid, typeUuid }))

      return () => {
        dispatch(resetTaskDetails())
        fetchTask.abort()
      }
    }
  }, [taskUuid])

  return (
    <MainLayout>
      <div className={'w-full'}>
        <Outlet />
      </div>
    </MainLayout>
  )
}
