import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeAgent } from '../../../../api/meetingTypeAgent'

export const getAllTypes = createAsyncThunk('meeting-types-v2/get-all-types', async ({ templateUuid }, thunkApi) => {
  try {
    const types = await MeetingTypeAgent.getAllTemplateMeetingTypes(templateUuid)

    return { types }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
