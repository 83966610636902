import { Fragment, useEffect, useState } from 'react'
import React from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import {
  changeRequestStatus,
  meetingsUnloaded,
  selectAllMeetings,
  selectRequestStatus,
} from '../../../reducers/meetings/meeting-list'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { getAllMeetings } from '../slice'
import { useDispatch, useSelector } from 'react-redux'
import { MeetingStatuses } from '../../../constants'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import Loader from '../../common/loader/Loader'

const statusesLabels = {
  [MeetingStatuses.CREATED]: 'Created',
  [MeetingStatuses.READY]: 'Ready',
  [MeetingStatuses.DELETED]: 'Deleted',
  [MeetingStatuses.ATTACHMENT_PROCESSING]: 'Attachment processing',
  [MeetingStatuses.ATTACHMENT_PROCESSING_ERROR]: 'Attachment processing error',
  [MeetingStatuses.DIALOG_PROCESSING]: 'Dialog processing',
  [MeetingStatuses.DIALOG_PROCESSING_ERROR]: 'Dialog processing error',
}

const statuses = {
  [MeetingStatuses.CREATED]: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  [MeetingStatuses.READY]: 'text-green-700 bg-green-50 ring-green-600/20',
  [MeetingStatuses.DELETED]: 'text-red-800 bg-red-50 ring-red-600/20',
  [MeetingStatuses.ATTACHMENT_PROCESSING]: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  [MeetingStatuses.ATTACHMENT_PROCESSING_ERROR]: 'text-red-800 bg-red-50 ring-red-600/20',
  [MeetingStatuses.DIALOG_PROCESSING]: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  [MeetingStatuses.DIALOG_PROCESSING_ERROR]: 'text-red-800 bg-red-50 ring-red-600/20',
}

export default function MeetingList() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const meetings = useSelector(selectAllMeetings)

  const [filteredMeeting, setFilteredMeeting] = useState([])
  const [meetingUuidForDelete, setMeetingUuidForDelete] = useState(null)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchMeetings = dispatch(getAllMeetings())

    return () => {
      dispatch(meetingsUnloaded())
      fetchMeetings.abort()
    }
  }, [])

  useEffect(() => {
    let filtered = []
    if (meetings && meetings.length > 0) {
      filtered = [...meetings]
      filtered.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))

      setFilteredMeeting(filtered)
    }
  }, [meetings])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <>
      {!meetings && requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}

      {meetings && filteredMeeting.length > 0 && (
        <>
          <div className="sticky top-16 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b bg-white border-black/5 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form className="flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500"
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-gray-900 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    type="search"
                    name="search"
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <ul role="list" className="divide-y divide-gray-100">
                {filteredMeeting.map((meeting) => (
                  <li key={meeting.uuid} className="relative w-full">
                    <Link to={`/meetings/${meeting.uuid}`}>
                      <div className="relative flex justify-between gap-x-6 py-5 ">
                        <div className="min-w-0">
                          <div className="flex items-start gap-x-3">
                            <p className="text-sm font-semibold leading-6 text-gray-900">{meeting.name}</p>
                            <p
                              className={classNames(
                                statuses[meeting.status],
                                'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                              )}
                            >
                              {statusesLabels[meeting.status]}
                            </p>
                          </div>
                          <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <p className="whitespace-nowrap">
                              {meeting.type ? meeting.type.name : 'Type is not defined'}
                            </p>
                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                              <circle cx={1} cy={1} r={1} />
                            </svg>
                            <p className="whitespace-nowrap">
                              <time dateTime={meeting.createdTime}>
                                {format(new Date(meeting.createdTime), 'MMMM do, yyyy H:mma')}
                              </time>
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-none items-center gap-x-4">
                          <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  )
}
