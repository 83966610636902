import React, { memo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import SingleFormWrapper from '../../common/singl-form-wrapper/SingleFormWrapper'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeEmailValue,
  changePasswordValue,
  changeRequestStatus,
  resetForm,
  selectError,
  selectFormData,
  selectRequestStatus,
} from '../../../reducers/admin-user/sign-in-form'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { signIn } from './slice'
import NotificationPopup from '../../common/notification-popup/NotificationPopup'
import Loader from '../../common/loader/Loader'

function SignIn() {
  const dispatch = useDispatch()

  const formData = useSelector(selectFormData)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  useEffect(() => {
    return () => {
      dispatch(resetForm())
    }
  }, [])

  const changeEmailFieldValue = (event) => {
    event.preventDefault()
    dispatch(changeEmailValue({ email: event.target.value }))
  }

  const changePasswordFieldValue = (event) => {
    event.preventDefault()
    dispatch(changePasswordValue({ password: event.target.value }))
  }

  const signInUser = (event) => {
    event.preventDefault()
    const { email, password } = formData
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(signIn({ email, password }))
  }

  return (
    <SingleFormWrapper>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to admin panel
        </h2>
        <div className="mt-4 text-center text-sm text-gray-500">Please provide your email and password</div>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-4">
          {error && <NotificationPopup message={error.message} />}
          <div className="relative -space-y-px rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                onChange={(e) => {
                  changeEmailFieldValue(e)
                }}
                required
                value={formData.email}
                className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                onChange={(e) => {
                  changePasswordFieldValue(e)
                }}
                required
                value={formData.password}
                className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Password"
              />
            </div>
          </div>

          <div className="mt-2 flex items-center justify-between">
            <div />
            <div className="text-sm">
              <Link to="/forgot-password" className="font-semibold text-indigo-600 hover:text-indigo-500">
                Forgot password?
              </Link>
            </div>
          </div>

          <div>
            <button
              disabled={requestStatus === REQUEST_STATUSES.PENDING}
              type="button"
              onClick={(e) => {
                signInUser(e)
              }}
              className={`flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            >
              {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
              <span>Sign in</span>
            </button>
          </div>
        </form>
      </div>
    </SingleFormWrapper>
  )
}

export default memo(SignIn)
