import { createAsyncThunk } from '@reduxjs/toolkit'
import { userAgent } from '../../api/userAgent'

export const getAllUsers = createAsyncThunk('users/get-all-users', async (undefined, thunkApi) => {
  try {
    const users = await userAgent.getAllUsers()

    return { users }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
export const getUsersStatistic = createAsyncThunk('users/statistic', async (undefined, thunkApi) => {
  try {
    const statistic = await userAgent.getStatistic()

    return { statistic }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
