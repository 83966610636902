import { createSlice } from '@reduxjs/toolkit'
import { createNewTag } from '../../../../components/meetings/types/details/tags/create/slice'
import { failed, REQUEST_STATUSES } from '../../../reducer-helper'

const initialState = {
  data: {
    name: '',
    prompt: '',
    description: '',
  },
  openForm: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'meetingTypeTagCreate',
  initialState,
  extraReducers(builder) {
    builder.addCase(createNewTag.fulfilled, (state, action) => {
      state.status = REQUEST_STATUSES.DONE
      state.openForm = false
    })
    builder.addCase(createNewTag.rejected, failed)
  },
  reducers: {
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changePrompt: (state, action) => {
      state.data.prompt = action.payload.prompt
    },
    changeDescription: (state, action) => {
      state.data.description = action.payload.description
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload.openForm
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.meetingTypeTagCreate
export const selectFormData = (state) => selectSlice(state).data

export const selectError = (state) => selectSlice(state).error

export const selectOpenForm = (state) => selectSlice(state).openForm

export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, resetForm, changeName, changePrompt, changeDescription, setOpenForm } = actions

export default reducer
