import { createSlice } from '@reduxjs/toolkit'
import { updateTemplate } from '../../../components/types-and-templates/template/update-form-popup/slice'

const initialState = {
  data: {
    name: '',
    description: '',
    systemTasks: '',
    isPopular: false,
    metaPrompt: '',
    userPrompt: '',
    workspaceUuid: '',
  },
  openForm: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'updateMeetingTypeTemplate',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateTemplate.fulfilled, () => initialState)
  },
  reducers: {
    formInit: (state, action) => {
      state.data.name = action.payload.name
      state.data.description = action.payload.description
      state.data.isPopular = action.payload.isPopular
      state.data.systemTasks = action.payload.systemTasks
      state.data.metaPrompt = action.payload.metaPrompt
      state.data.userPrompt = action.payload.userPrompt
      state.data.workspaceUuid = action.payload.workspaceUuid
    },
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changeDescription: (state, action) => {
      state.data.description = action.payload.description
    },
    changeSystemTasks: (state, action) => {
      state.data.systemTasks = action.payload.systemTasks
    },
    changeIsPopular: (state, action) => {
      state.data.isPopular = action.payload.isPopular
    },
    changeMetaPrompt: (state, action) => {
      state.data.metaPrompt = action.payload.metaPrompt
    },
    changeUserPrompt: (state, action) => {
      state.data.userPrompt = action.payload.userPrompt
    },
    changeWorkspaceUuid: (state, action) => {
      state.data.workspaceUuid = action.payload.workspaceUuid
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload.openForm
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.updateMeetingTypeTemplate
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectOpenForm = (state) => selectSlice(state).openForm
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const {
  formInit,
  changeRequestStatus,
  resetForm,
  changeName,
  changeDescription,
  changeIsPopular,
  changeSystemTasks,
  changeMetaPrompt,
  changeUserPrompt,
  changeWorkspaceUuid,
  setOpenForm,
} = actions

export default reducer
