import React, { memo } from 'react'
import { FingerPrintIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'

const secondaryNavigation = [
  { name: 'Profile', href: '/settings/general', icon: UserCircleIcon },
  { name: 'Security', href: '/settings/security', icon: FingerPrintIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function SettingsSidebar() {
  return (
    <aside className="flex overflow-x-auto border-b border-gray-900/5 lg:block lg:w-64 pb-4 lg:pb-0 lg:flex-none lg:border-0">
      <nav className="flex-none px-4 sm:px-6 lg:px-0">
        <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
          {secondaryNavigation.map((item) => (
            <li key={item.name}>
              <NavLink to={item.href}>
                {({ isActive }) => {
                  return (
                    <div
                      className={classNames(
                        isActive
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold',
                      )}
                    >
                      <item.icon
                        className={classNames(
                          isActive ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                          'h-6 w-6 shrink-0',
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </div>
                  )
                }}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  )
}

export default memo(SettingsSidebar)
