import { createSlice } from '@reduxjs/toolkit'
import { ComponentRequestStatus } from '../../constants'
import { signIn } from '../../components/admin-user/sign-in/slice'
import { failed } from '../reducer-helper'
import { initApplication } from '../../components/app/slice'
import { confirmNewEmail, updateProfileInformation } from '../../components/admin-user/settings/general/slice'

const initialState = {
  user: null,
  error: null,
  status: null,
}

function success(state) {
  state.status = ComponentRequestStatus.SUCCESS
  delete state.error
}

const slice = createSlice({
  name: 'adminUser',
  initialState,
  extraReducers(builder) {
    builder.addCase(initApplication.fulfilled, (state, action) => {
      state.user = action.payload.user
    })
    builder.addCase(updateProfileInformation.fulfilled, (state, action) => {
      state.user.profile.fullName = action.payload.fullName
    })
    builder.addCase(confirmNewEmail.fulfilled, (state, action) => {
      state.user.email = action.payload.email
    })
    builder.addCase(signIn.fulfilled, (state, action) => {
      success(state)
      state.user = action.payload.user
    })
    builder.addCase(signIn.rejected, failed)
  },
})

const selectUserSlice = (state) => state.adminUser
export const selectUser = (state) => selectUserSlice(state).user
export const selectIsLoading = (state) => selectUserSlice(state).status === ComponentRequestStatus.LOADING
export const selectError = (state) => selectUserSlice(state).error

const { reducer } = slice

export default reducer
