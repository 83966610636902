import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingAgent } from '../../../../../../api/meetingAgent'

export const createNewTag = createAsyncThunk(
  'meetings/types/tags/create',
  async ({ meetingTypeUuid, name, description, prompt }, thunkApi) => {
    try {
      const tag = await MeetingAgent.createNewMeetingTypeTag(meetingTypeUuid, name, description, prompt)

      return { tag }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
