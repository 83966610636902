import React from 'react'
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { BriefcaseIcon, Cog6ToothIcon, FolderIcon, HomeIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Logo from '../../../../assets/images/as_new_logo_full.svg'
import { ReactSVG } from 'react-svg'
import { Link, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../../../reducers/admin-user/user'
import { logout } from './slice'
import { ChevronDownIcon } from '@heroicons/react/16/solid'

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon },
  { name: 'Users', href: '/users', icon: UsersIcon },
  { name: 'Meetings', href: '/meetings', icon: FolderIcon },
  { name: 'Types & Templates', href: '/meeting-type-templates', icon: BriefcaseIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MainLayout({ children }) {
  const dispatch = useDispatch()

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const user = useSelector(selectUser)

  const logOutProcess = () => {
    dispatch(logout())
  }

  const userNavigation = [
    { name: 'Settings', href: '/settings/general' },
    { name: 'Sign out', isButton: true, handler: logOutProcess },
  ]

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <Link to={'/dashboard'}>
                        <ReactSVG
                          src={Logo}
                          beforeInjection={(svg) => {
                            svg.setAttribute('style', 'height: 16px;')
                          }}
                        />
                      </Link>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <NavLink to={item.href}>
                                  {({ isActive }) => {
                                    return (
                                      <div
                                        className={classNames(
                                          isActive
                                            ? 'bg-gray-50 text-indigo-600'
                                            : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                        )}
                                      >
                                        <item.icon
                                          className={classNames(
                                            isActive ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                            'h-6 w-6 shrink-0',
                                          )}
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </div>
                                    )
                                  }}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <NavLink
                            to="/portal/settings"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            Portal settings
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 justify-start items-center">
              <Link className={'flex justify-start'} to={'/dashboard'}>
                <ReactSVG
                  src={Logo}
                  beforeInjection={(svg) => {
                    svg.setAttribute('style', 'width: fit-content; height: 16px;')
                  }}
                />
              </Link>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <NavLink to={item.href}>
                          {({ isActive }) => {
                            return (
                              <div
                                className={classNames(
                                  isActive
                                    ? 'bg-gray-50 text-indigo-600'
                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    isActive ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                    'h-6 w-6 shrink-0',
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </div>
                            )
                          }}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <NavLink
                    to="/portal/settings"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                      aria-hidden="true"
                    />
                    Portal settings
                  </NavLink>
                </li>
                <li className="-mx-6 mt-auto">
                  <Menu
                    as="div"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                  >
                    <Menu.Button className="w-full flex items-center p-1.5">
                      <span className="sr-only">Open user menu</span>
                      <span className="flex items-center">
                        <span className="text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                          {user.profile.fullName}
                        </span>
                        <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-40 z-10 w-full rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => {
                              if (item.isButton) {
                                return (
                                  <button
                                    onClick={item.handler}
                                    className={classNames(
                                      active ? 'bg-gray-50' : '',
                                      'w-full text-left block px-3 py-1 text-sm leading-6 text-gray-900',
                                    )}
                                  >
                                    {item.name}
                                  </button>
                                )
                              } else {
                                return (
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-50' : '',
                                      'block px-3 py-1 text-sm leading-6 text-gray-900',
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                )
                              }
                            }}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  {/*<button*/}
                  {/*  href="#"*/}
                  {/*  className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"*/}
                  {/*>*/}
                  {/*  <span className="sr-only">Open admin-admin-user menu</span>*/}
                  {/*  <span className="flex items-center">*/}
                  {/*    <span className="text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">*/}
                  {/*      {admin-admin-user.profile.fullName}*/}
                  {/*    </span>*/}
                  {/*    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                  {/*  </span>*/}
                  {/*</button>*/}
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          {/*<div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-black/5 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">*/}
          {/*  <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>*/}
          {/*    <span className="sr-only">Open sidebar</span>*/}
          {/*    <Bars3Icon className="h-6 w-6" aria-hidden="true" />*/}
          {/*  </button>*/}

          {/*  <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />*/}

          {/*  /!*<Header />*!/*/}
          {/*</div>*/}

          <main>{children}</main>
        </div>
      </div>
    </>
  )
}
