import React, { memo } from 'react'
import { Link } from 'react-router-dom'

function Success() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-6xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Your password has been successfully changed
          </h2>
          <div className="mt-4 text-center text-sm text-gray-500">
            Now you can Sign In to the platform using your new credentials
          </div>
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className={'mt-8'}>
            <Link
              to={'/sign-in'}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Success)
