import { Fragment, useEffect, useState } from 'react'
import React from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeRequestStatus,
  typesUnloaded,
  selectAllTypes,
  selectRequestStatus,
} from '../../../reducers/meetings/types/type-list'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { getAllTypes } from './slice'
import { ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline'
import CreateType from './create/CreateType'
import { selectOpenForm, setOpenForm } from '../../../reducers/meetings/types/create'

export default function MeetingTypes() {
  const dispatch = useDispatch()

  const openNewTypeForm = useSelector(selectOpenForm)
  const setOpenNewTypeForm = (status) => {
    dispatch(setOpenForm({ openForm: status }))
  }

  const requestStatus = useSelector(selectRequestStatus)
  const types = useSelector(selectAllTypes)

  const [filteredTypes, setFilteredTypes] = useState([])
  const [typeUuidForDelete, setTypeUuidForDelete] = useState(null)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchTypes = dispatch(getAllTypes())

    return () => {
      dispatch(typesUnloaded())
      fetchTypes.abort()
    }
  }, [])

  useEffect(() => {
    let filtered = []
    if (types && types.length > 0) {
      filtered = [...types]
      filtered.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))

      setFilteredTypes(filtered)
    }
  }, [types])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <>
      <div className="sticky top-16 z-1 flex h-16 shrink-0 items-center gap-x-6 border-b bg-white border-black/5 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <form className="flex flex-1" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative w-full">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-gray-900 focus:ring-0 sm:text-sm"
                placeholder="Search..."
                type="search"
                name="search"
              />
            </div>
          </form>
          <div className="flex items-center gap-x-4 lg:gap-x-6">
            <button
              type="button"
              onClick={() => setOpenNewTypeForm(true)}
              className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Create new type
            </button>
          </div>
        </div>
      </div>
      <div className="py-4 px-4 sm:px-6 lg:px-8">
        <ul role="list" className="divide-y divide-gray-100 ">
          {filteredTypes.map((type) => (
            <li key={type.uuid}>
              <Link to={`/meetings/types/${type.uuid}`}>
                <div className="flex items-center justify-between gap-x-6 py-5">
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-sm font-semibold leading-6 text-gray-900">{type.name}</p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <p className="whitespace-nowrap">Tags: {type.tags ? type.tags.length : 0}</p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="z-30">
        <CreateType open={openNewTypeForm} setOpen={setOpenNewTypeForm} />
      </div>
    </>
  )
}
