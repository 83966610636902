'use client'

import React, { useState } from 'react'
import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from '../../../../common/outside-click-handler/OutsideClickHandler'
import {
  changeContentType,
  changeName as changeColumnName,
  changeResponseType,
  resetForm as resetAddColumnForm,
  selectFormData as selectAddColumnFormData,
  selectOpenForm as selectAddColumnOpenForm,
  setOpenForm as setAddColumnOpenForm,
} from '../../../../../reducers/types-and-templates/meeting-type-task/add-column'
import { addColumn } from '../../../../../reducers/types-and-templates/meeting-type-task/constructor'

export default function AddColumnForm({ section, sectionIndex }) {
  const dispatch = useDispatch()

  const showAddColumnForm = useSelector(selectAddColumnOpenForm)
  const addColumnFormData = useSelector(selectAddColumnFormData)

  const [addColumnFormError, setAddColumnFormError] = useState(false)

  const setOpenAddColumnFormProcess = () => {
    dispatch(setAddColumnOpenForm({ openForm: sectionIndex }))
  }

  const closeAddColumnForm = () => {
    dispatch(resetAddColumnForm())
    setOpenAddColumnFormProcess(null)
  }

  const addColProcess = (event) => {
    event.preventDefault()

    if (addColumnFormData.name.trim() === '') {
      setAddColumnFormError(true)
    } else {
      dispatch(
        addColumn({
          index: sectionIndex,
          column: {
            name: addColumnFormData.name,
            contentType: addColumnFormData.contentType,
            responseType: addColumnFormData.responseType,
          },
        }),
      )
      dispatch(resetAddColumnForm())
      setAddColumnFormError(false)
    }
  }

  const changeColumnNameProcess = (event) => {
    if (event.target.value.trim() === '') {
      setAddColumnFormError(true)
    }
    dispatch(changeColumnName({ name: event.target.value }))
  }

  const changeColumnContentTypeProcess = (event) => {
    dispatch(changeContentType({ contentType: event.target.value }))
  }

  const changeColumnResponseTypeProcess = (event) => {
    dispatch(changeResponseType({ responseType: event.target.value }))
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className={classNames(section.cols.length > 0 && 'w-14')}>
      <button
        type={'button'}
        onClick={() => {
          setOpenAddColumnFormProcess()
        }}
        className={classNames(
          'flex text-sm text-gray-500 flex-row items-center px-4 py-2 hover:bg-indigo-50 transition-all ring-1 ring-gray-200 rounded-md',
          showAddColumnForm === sectionIndex ? 'bg-indigo-50 opacity-50' : 'bg-gray-50',
        )}
      >
        <PlusIcon className={classNames('w-5 h-5 text-gray-700', section.cols.length === 0 && 'mr-2')} />
        {section.cols.length === 0 && 'Add new column'}
      </button>

      {showAddColumnForm === sectionIndex && (
        <OutsideClickHandler
          onOutsideClick={() => {
            closeAddColumnForm()
          }}
        >
          <form
            onSubmit={(event) => {
              addColProcess(event)
            }}
            className={classNames(
              'absolute p-4 rounded-xl shadow ring-1 ring-gray-200 bg-white w-full max-w-80 mt-2',
              section.cols.length > 0 && '-ml-64',
            )}
          >
            <div>
              <input
                type={'text'}
                placeholder={addColumnFormError ? 'Column name is required...' : 'Column name'}
                autoFocus={true}
                onChange={changeColumnNameProcess}
                value={addColumnFormData.name}
                className={classNames(
                  'w-full rounded-md border-none focus:outline-none focus:border-none ring-1 text-sm text-gray-700',
                  addColumnFormError ? 'ring-red-600 focus:ring-red-600' : '',
                )}
                name={'task-name'}
              />
            </div>
            <div className={'mt-2'}>
              <label htmlFor="location" className="block text-xs leading-6 text-gray-500">
                Content type
              </label>
              <select
                id="location"
                name="content-type"
                defaultValue="text"
                onChange={changeColumnContentTypeProcess}
                className="mt-1 text-sm block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:leading-6"
              >
                <option value={'text'}>Plain text</option>
                <option value={'ai-task'}>AI task</option>
              </select>
            </div>
            {addColumnFormData.contentType === 'ai-task' && (
              <div className={'mt-2'}>
                <label htmlFor="location" className="block text-xs leading-6 text-gray-500">
                  AI response type
                </label>
                <select
                  id="location"
                  name="response-type"
                  defaultValue="text"
                  onChange={changeColumnResponseTypeProcess}
                  className="mt-1 text-sm block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:leading-6"
                >
                  <option value={'text'}>Plain text</option>
                  <option value={'boolean'}>Boolean</option>
                </select>
              </div>
            )}
            <div className={'mt-4'}>
              <button
                type={'submit'}
                className={classNames(
                  'ml-auto flex items-center rounded-md bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
                )}
              >
                <CheckIcon className={'w-4 h-4 text-gray-700 mr-2'} />
                Add column
              </button>
            </div>
          </form>
        </OutsideClickHandler>
      )}
    </div>
  )
}
