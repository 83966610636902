export const ComponentRequestStatus = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
}

export const MeetingStatuses = {
  CREATED: 'created',
  READY: 'ready',
  DELETED: 'deleted',
  ATTACHMENT_PROCESSING: 'attachment-processing',
  ATTACHMENT_PROCESSING_ERROR: 'attachment-processing-errors',
  DIALOG_PROCESSING: 'dialog-processing',
  DIALOG_PROCESSING_ERROR: 'dialog-processing-errors',
}

export const MeetingLanguagesSelectboxOptions = [
  {
    index: 'ukrainian',
    title: 'Ukrainian',
  },
  {
    index: 'english',
    title: 'English',
  },
]
