import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeTaskAgent } from '../../../../api/meetingTypeTaskAgent'

export const deleteTask = createAsyncThunk('meeting-type-task/delete', async ({ taskUuid, typeUuid }, thunkApi) => {
  try {
    await MeetingTypeTaskAgent.deleteTask(taskUuid, typeUuid)

    return { deletedUuid: taskUuid }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
