import { requests } from './agent'

export const MeetingTypeTaskAgent = {
  getMeetingTypeTasks: (typeUuid) => {
    return requests.get(`/1_0/admin/meeting-types/${typeUuid}/tasks/list`)
  },

  getTaskDetails: (taskUuid, typeUuid) => {
    return requests.get(`/1_0/admin/meeting-types/${typeUuid}/tasks/${taskUuid}`)
  },

  createNewTask: (typeUuid, name) => {
    return requests.post(`/1_0/admin/meeting-types/${typeUuid}/tasks/create`, {
      name,
    })
  },

  renameTask: (taskUuid, typeUuid, name) => {
    return requests.post(`/1_0/admin/meeting-types/${typeUuid}/tasks/${taskUuid}/rename`, {
      name,
    })
  },

  updateTaskDescription: (taskUuid, typeUuid, description) => {
    return requests.post(`/1_0/admin/meeting-types/${typeUuid}/tasks/${taskUuid}/update-description`, {
      description,
    })
  },

  updateTaskContent: (taskUuid, typeUuid, content) => {
    return requests.post(`/1_0/admin/meeting-types/${typeUuid}/tasks/${taskUuid}/update-content`, {
      content,
    })
  },

  deleteTask: (taskUuid, typeUuid) => {
    return requests.post(`/1_0/admin/meeting-types/${typeUuid}/tasks/${taskUuid}/delete`)
  },

  reorderTask: (typeUuid, newOrdering) => {
    return requests.post(`/1_0/admin/meeting-types/${typeUuid}/reorder-tasks`, {
      newOrdering,
    })
  },
}
