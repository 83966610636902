import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeTemplateAgent } from '../../../../api/meetingTypeTemplateAgent'

export const updateTemplate = createAsyncThunk(
  'meeting-type-template/update-template',
  async ({ uuid, name, description, systemTasks, isPopular, metaPrompt, userPrompt, workspaceUuid }, thunkApi) => {
    try {
      const template = await MeetingTypeTemplateAgent.updateMeetingTypeTemplate(
        uuid,
        name,
        description,
        systemTasks,
        isPopular,
        metaPrompt,
        userPrompt,
        workspaceUuid,
      )

      return { template }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
