import agent from '../api/agent'
import { signIn } from '../components/admin-user/sign-in/slice'
import { logout } from '../components/common/protected/main-layout/slice'

const localStorageMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case signIn.fulfilled.type:
      window.localStorage.setItem('admin-api-token', action.payload.apiToken)
      agent.setApiToken(action.payload.apiToken)
      break

    case logout.fulfilled.type:
      window.localStorage.removeItem('admin-api-token')
      agent.setApiToken(undefined)
      break
  }

  return next(action)
}

export { localStorageMiddleware }
