import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminUserAgent } from '../../../api/adminUserAgent'

export const resetPassword = createAsyncThunk('admin-user/account/reset-password', async ({ email }, thunkApi) => {
  try {
    const response = await AdminUserAgent.forgotPasswordResetPassword(email)

    return response
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const resetPasswordConfirmEmail = createAsyncThunk(
  'admin-user/account/reset-password/confirm-email',
  async ({ token }, thunkApi) => {
    try {
      const response = await AdminUserAgent.forgotPasswordConfirmEmail(token)

      return { token: response.token }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const changePassword = createAsyncThunk(
  'admin-user/account/change-password',
  async ({ token, password }, thunkApi) => {
    try {
      const response = await AdminUserAgent.forgotPasswordSetNewPassword(token, password)

      return response
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)
