import React, { useEffect, useRef } from 'react'

const OutsideClickHandler = ({ children, onOutsideClick }) => {
  const ref = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the referenced component
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick()
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside)

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onOutsideClick])

  return <div ref={ref}>{children}</div>
}

export default OutsideClickHandler
