import React from 'react'
import MainLayout from '../common/protected/main-layout/MainLayout'
import { NavLink, Outlet } from 'react-router-dom'

export default function Meetings() {
  return (
    <MainLayout>
      <header className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-6 border-b bg-white border-black/5">
        {/* Secondary navigation */}
        <nav className="flex overflow-x-auto border-b h-16 border-black/5 py-4">
          <ul
            role="list"
            className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-8 text-gray-400 sm:px-6 lg:px-8"
          >
            <li>
              <NavLink to={'/meetings'} end>
                {({ isActive }) => {
                  return <span className={isActive ? 'text-indigo-400' : ''}>Meetings</span>
                }}
              </NavLink>
            </li>
            <li>
              <NavLink to={'/meetings/types'}>
                {({ isActive }) => {
                  return <span className={isActive ? 'text-indigo-400' : ''}>Types</span>
                }}
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
      <div>
        <Outlet />
      </div>
    </MainLayout>
  )
}
