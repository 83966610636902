import { requests } from './agent'

export const MeetingTypeTemplateAgent = {
  getAllMeetingTypeTemplates: () => {
    return requests.get('/1_0/admin/meeting-type-templates/list')
  },

  getMeetingTypeTemplateDetails: (templateUuid) => {
    return requests.get(`/1_0/admin/meeting-type-templates/${templateUuid}`)
  },

  createNewMeetingTypeTemplate: (name, description, systemTasks, isPopular, metaPrompt, userPrompt, workspaceUuid) => {
    return requests.post('/1_0/admin/meeting-type-templates/create', {
      name,
      description,
      systemTasks,
      isPopular,
      metaPrompt,
      userPrompt,
      workspaceUuid,
    })
  },

  updateMeetingTypeTemplate: (
    templateUuid,
    name,
    description,
    systemTasks,
    isPopular,
    metaPrompt,
    userPrompt,
    workspaceUuid,
  ) => {
    return requests.post(`/1_0/admin/meeting-type-templates/${templateUuid}/update`, {
      name,
      description,
      systemTasks,
      isPopular,
      metaPrompt,
      userPrompt,
      workspaceUuid,
    })
  },

  deleteMeetingTypeTemplate: (templateUuid) => {
    return requests.post(`/1_0/admin/meeting-type-templates/${templateUuid}/delete`)
  },
}
