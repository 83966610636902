import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeAgent } from '../../../../api/meetingTypeAgent'

export const deleteMeetingType = createAsyncThunk(
  'meeting-type-v2/delete',
  async ({ uuid, templateUuid }, thunkApi) => {
    try {
      await MeetingTypeAgent.deleteMeetingType(uuid, templateUuid)

      return { deletedUuid: uuid, templateUuid }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
