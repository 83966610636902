import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../reducers/admin-user/user'
import { useNavigate } from 'react-router'

const ProtectedRoute = ({ children }) => {
  const [authChecked, setAuthChecked] = useState(false)
  const user = useSelector(selectUser)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      navigate('/sign-in')
    }

    setAuthChecked(true)
  }, [])

  return authChecked ? <>{children}</> : <span>Checking</span>
}

export default ProtectedRoute
