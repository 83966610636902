import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeRequestStatus,
  selectStatistic,
  selectRequestStatus,
  statisticUnloaded,
} from '../../reducers/users/user-statistic'
import { REQUEST_STATUSES } from '../../reducers/reducer-helper'
import { getUsersStatistic } from './slice'

export default function Statistic() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const statistic = useSelector(selectStatistic)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchStatistic = dispatch(getUsersStatistic())

    return () => {
      dispatch(statisticUnloaded())
      fetchStatistic.abort()
    }
  }, [])

  const stats = [
    { name: 'Total users', value: statistic ? statistic.total : 'Loading' },
    { name: 'New today', value: statistic ? statistic.newToday : 'Loading' },
    { name: 'New for last 30 days', value: statistic ? statistic.new30Days : 'Loading' },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="grid grid-cols-1 bg-white sm:grid-cols-2 lg:grid-cols-3">
      {stats.map((stat, statIdx) => (
        <div
          key={stat.name}
          className={classNames(
            statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
            'border-b border-black/5 py-6 px-4 sm:px-6 lg:px-8',
          )}
        >
          <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
          <p className="mt-2 flex items-baseline gap-x-2">
            <span className="text-4xl font-semibold tracking-tight text-gray-900">{stat.value}</span>
          </p>
        </div>
      ))}
    </div>
  )
}
