import React, { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PlusIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { changeRequestStatus } from '../../../../reducers/users/user-list'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { useDispatch, useSelector } from 'react-redux'
import {
  formInit,
  changeName,
  changeDescription,
  changeContext,
  addVariable,
  deleteVariable,
  changeVariable,
  changeWorkspaceUuid,
  selectFormData,
} from '../../../../reducers/types-and-templates/meeting-type/update-type'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { selectTypeDetails } from '../../../../reducers/types-and-templates/meeting-type/type-details'
import { updateMeetingType } from './slice'

export default function UpdateMeetingType({ open, setOpen }) {
  const dispatch = useDispatch()

  const formData = useSelector(selectFormData)

  const type = useSelector(selectTypeDetails)

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }
  const changeDescriptionProcess = (event) => {
    event.preventDefault()
    dispatch(changeDescription({ description: event.target.value }))
  }
  const changeContextProcess = (event) => {
    dispatch(changeContext({ context: event.target.value }))
  }
  const addVariableProcess = (event) => {
    event.preventDefault()
    dispatch(addVariable())
  }
  const deleteVariableProcess = (indexForDelete) => {
    dispatch(deleteVariable({ indexForDelete }))
  }
  const changeVariableNameProcess = (name, index) => {
    dispatch(changeVariable({ index, variable: { name, value: formData.variables[index].value } }))
  }
  const changeVariableValueProcess = (value, index) => {
    dispatch(changeVariable({ index, variable: { name: formData.variables[index].name.toUpperCase(), value } }))
  }
  const changeWorkspaceUuidProcess = (event) => {
    dispatch(changeWorkspaceUuid({ workspaceUuid: event.target.value }))
  }

  useEffect(() => {
    if (type) {
      dispatch(
        formInit({
          name: type.name,
          description: type.description,
          context: type.context,
          variables: type.variables,
          workspaceUuid: type.workspaceUuid,
        }),
      )
    }
  }, [type])

  const updateMeetingTypeProcess = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      updateMeetingType({
        uuid: type.uuid,
        name: formData.name,
        description: formData.description,
        context: formData.context,
        variables: formData.variables,
        templateUuid: type.templateUuid,
        workspaceUuid: formData.workspaceUuid,
      }),
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form
                    onSubmit={updateMeetingTypeProcess}
                    className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                  >
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Create new meeting type
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Please fill the form below to create a new meeting type
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Name
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              onChange={changeNameProcess}
                              value={formData.name}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Description
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <textarea
                              id="description"
                              name="description"
                              onChange={changeDescriptionProcess}
                              value={formData.description}
                              rows={6}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Meeting type context
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <textarea
                              id="type-context"
                              name="type-context"
                              onChange={changeContextProcess}
                              value={formData.context}
                              rows={6}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div className={'text-sm font-medium leading-6 text-gray-900'}>Variables</div>
                          <div className={'flex flex-col gap-y-4 pt-4'}>
                            {!formData.variables ||
                              (formData.variables.length === 0 && (
                                <div className={'text-gray-500 text-sm'}>There are no variables yet.</div>
                              ))}
                            {formData.variables &&
                              formData.variables.map((variable, index) => (
                                <div key={`variable-${index}`} className={'p-4 rounded-md bg-gray-50'}>
                                  <div className={'space-y-2'}>
                                    <div className={'relative'}>
                                      <input
                                        type="text"
                                        name={`variable-${index}-name`}
                                        placeholder={'Name'}
                                        id={`variable-${index}-name`}
                                        onChange={(event) => {
                                          changeVariableNameProcess(event.target.value, index)
                                        }}
                                        value={formData.variables[index].name}
                                        className="block w-full uppercase rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                      <div
                                        className={
                                          'absolute top-2 right-2 text-gray-500 hover:text-gray-700 cursor-pointer'
                                        }
                                      >
                                        <InformationCircleIcon className={'w-5 h-5'} />
                                      </div>
                                    </div>
                                    <div>
                                      <textarea
                                        id={`variable-${index}-value`}
                                        placeholder={'Value'}
                                        name={`variable-${index}-value`}
                                        onChange={(event) => {
                                          changeVariableValueProcess(event.target.value, index)
                                        }}
                                        value={formData.variables[index].value}
                                        rows={3}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className={'pt-4 w-full flex justify-end'}>
                                    <button
                                      type="button"
                                      className="rounded-md flex flex-row items-center bg-white px-3 py-2 text-sm font-semibold text-gray-500 hover:text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                      onClick={() => deleteVariableProcess(index)}
                                    >
                                      <TrashIcon className={'w-5 h-5 mr-2'} />
                                      Delete variable
                                    </button>
                                  </div>
                                </div>
                              ))}
                            <div className={'py-4'}>
                              <button
                                onClick={addVariableProcess}
                                type={'button'}
                                className="flex flex-row items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              >
                                <PlusIcon className={'w-5 h-5 mr-2'} />
                                Add variable
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Attached to workspace
                              <br />
                              <span className="text-gray-300 text-xs ">(Optional)</span>
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              name="workspace"
                              id="workspace"
                              onChange={changeWorkspaceUuidProcess}
                              value={formData.workspaceUuid}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
