import { Fragment, useState } from 'react'
import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import {
  selectOpenForm as selectOpenUpdateTemplateForm,
  setOpenForm as setOpenUpdateTemplateForm,
} from '../../../../reducers/types-and-templates/meeting-type-templates/update-templates'

import { selectTemplateDetails } from '../../../../reducers/types-and-templates/meeting-type-templates/template-details'
import Breadcrumbs from '../../../common/breadcrumbs/Breadcrumbs'
import UpdateMeetingTypeTemplate from '../update-form-popup/UpdateMeetingTypeTemplate'
import ConfirmDeleteTemplatePopup from '../confirm-delete-popup/ConfirmDeletePopup'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'

export default function TemplateDetails() {
  const dispatch = useDispatch()

  const { templateUuid } = useParams()
  const [showDeletePopup, setShowDeletePopup] = useState(false)

  const template = useSelector(selectTemplateDetails)

  const openUpdateTemplateForm = useSelector(selectOpenUpdateTemplateForm)
  const setOpenUpdateTemplateFormProcess = (status) => {
    dispatch(setOpenUpdateTemplateForm({ openForm: status }))
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const pages = [
    { name: 'Meeting type templates', href: '/meeting-type-templates', current: false },
    { name: template ? template.name : 'Loading...', href: '#', current: true },
  ]

  return (
    <>
      <div className="sticky z-40 flex h-16 shrink-0 items-center gap-x-6 border-b bg-white border-black/5 px-4 sm:px-6 lg:px-8">
        <div>
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      {template && (
        <div className="py-4 px-4 sm:px-6 lg:px-8">
          <div>
            <div className={'flex flex-row justify-between items-center'}>
              <div className="flex flex-row items-center px-4 sm:px-0">
                <NavLink
                  className={'p-4 rounded-md bg-gray-50 hover:bg-indigo-50 mr-6 cursor-pointer'}
                  to={`/meeting-type-templates`}
                >
                  <ArrowLeftIcon className={'w-5 h-5'} />
                </NavLink>
                <div>
                  <h3 className="text-base font-semibold leading-7 text-gray-900">Meeting type template information</h3>
                  <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Meeting template details and prompts</p>
                </div>
              </div>
              <div className={'flex flex-row gap-x-4 items-center'}>
                <div>
                  <NavLink
                    to={`/meeting-type-templates/${templateUuid}/meeting-types`}
                    className="flex items-center rounded-md bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    <span>Meeting types</span>
                    <ArrowRightIcon className={'ml-2 w-4 h-4'} />
                  </NavLink>
                </div>
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  <Menu as="div" className="relative flex-none">
                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setOpenUpdateTemplateFormProcess(true)
                              }}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                              )}
                            >
                              Update template
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setShowDeletePopup(true)
                              }}
                              className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900',
                              )}
                            >
                              Delete template
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-normal leading-6 text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{template.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-normal leading-6 text-gray-500">Description</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{template.description}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-normal leading-6 text-gray-500">Is popular</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <span className={'font-semibold'}>{template.isPopular ? 'Enabled' : 'Disabled'}</span>
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-normal leading-6 text-gray-500">System tasks (JSON)</dt>
                  <dd className="mt-1 text-sm leading-6 whitespace-pre-line text-gray-700 sm:col-span-2 sm:mt-0">
                    {template.systemTasks}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-normal leading-6 text-gray-500">Meta prompt</dt>
                  <dd className="mt-1 text-sm leading-6 whitespace-pre-line text-gray-700 sm:col-span-2 sm:mt-0">
                    {template.metaPrompt}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-normal leading-6 text-gray-500">User prompt</dt>
                  <dd className="mt-1 text-sm leading-6 whitespace-pre-line text-gray-700 sm:col-span-2 sm:mt-0">
                    {template.userPrompt}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Workspace uuid</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {template.workspaceUuid
                      ? `Connected to ${template.workspaceUuid} workspacce`
                      : 'Not connected to any workspace'}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      )}
      <UpdateMeetingTypeTemplate open={openUpdateTemplateForm} setOpen={setOpenUpdateTemplateFormProcess} />
      <ConfirmDeleteTemplatePopup open={showDeletePopup} setOpen={setShowDeletePopup} templateUuid={templateUuid} />
    </>
  )
}
