import { requests } from './agent'

export const userAgent = {
  getAllUsers: () => {
    return requests.post('/1_0/admin/users/list')
  },

  getStatistic: () => {
    return requests.get('/1_0/admin/users/statistic')
  },
}
