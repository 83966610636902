import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeTaskAgent } from '../../../api/meetingTypeTaskAgent'

export const createNewTask = createAsyncThunk(
  'meeting-type-tasks/create-new-task',
  async ({ typeUuid, name }, thunkApi) => {
    try {
      const { task, type } = await MeetingTypeTaskAgent.createNewTask(typeUuid, name)

      return { task, type }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const getAllTasks = createAsyncThunk(
  'meeting-type-tasks/get-all-tasks',
  async ({ typeUuid, orderingMap }, thunkApi) => {
    try {
      const tasks = await MeetingTypeTaskAgent.getMeetingTypeTasks(typeUuid)

      if (orderingMap) {
        const orderMap = new Map()
        orderingMap.forEach((uuid, index) => {
          orderMap[uuid] = index
        })

        tasks.sort((a, b) => {
          const indexA = orderMap[a.uuid] !== undefined ? orderMap[a.uuid] : -1
          const indexB = orderMap[b.uuid] !== undefined ? orderMap[b.uuid] : -1

          return indexA - indexB
        })
      }

      return { tasks }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const reorderTask = createAsyncThunk(
  'meeting-type-tasks/reorder',
  async ({ typeUuid, newOrdering }, thunkApi) => {
    try {
      const tasks = await MeetingTypeTaskAgent.reorderTask(typeUuid, newOrdering)

      return { tasks }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
