import { requests } from './agent'

export const AdminUserAgent = {
  signIn: (email, password) => {
    return requests.post('/1_0/admin/admin-user/account/sign-in', { email, password })
  },
  update: (fullName, email, password) => {
    return requests.post('/1_0/admin/admin-user/update', { fullName, email, password })
  },
  confirmNewEmail: (token) => {
    return requests.post('/1_0/admin/admin-user/account/change-email/confirm-new-email', { token })
  },
  changePassword: (newPassword, oldPassword) => {
    return requests.post('/1_0/admin/admin-user/account/change-password', { newPassword, oldPassword })
  },
  forgotPasswordSetNewPassword: (token, password) => {
    return requests.post('/1_0/admin/admin-user/account/forgot-password/restore', { token, newPassword: password })
  },
  forgotPasswordResetPassword: (email) => {
    return requests.post('/1_0/admin/admin-user/account/forgot-password/reset', { email })
  },
  forgotPasswordConfirmEmail: (token) => {
    return requests.post('/1_0/admin/admin-user/account/forgot-password/confirm-email', { token })
  },
  logOut: () => {
    return requests.post('/1_0/admin/admin-user/logout')
  },
}
