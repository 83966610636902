import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeTemplateAgent } from '../../../../api/meetingTypeTemplateAgent'

export const deleteTemplate = createAsyncThunk('meeting-type-template/delete', async ({ uuid }, thunkApi) => {
  try {
    await MeetingTypeTemplateAgent.deleteMeetingTypeTemplate(uuid)

    return { deletedUuid: uuid }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
