import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    name: '',
    contentType: 'text',
    responseType: 'text',
  },
  openForm: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'updateColumn',
  initialState,
  reducers: {
    formInit: (state, action) => {
      state.data.name = action.payload.name
      state.data.contentType = action.payload.contentType
      state.data.responseType = action.payload.responseType
    },
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changeContentType: (state, action) => {
      state.data.contentType = action.payload.contentType
    },
    changeResponseType: (state, action) => {
      state.data.responseType = action.payload.responseType
    },
    setOpenForm: (state, action) => {
      state.data.name = action.payload.name
      state.data.contentType = action.payload.contentType
      state.data.responseType = action.payload.responseType
      state.openForm = action.payload.openForm
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.updateColumn
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectOpenForm = (state) => selectSlice(state).openForm

const { reducer, actions } = slice

export const {
  changeRequestStatus,
  formInit,
  changeResponseType,
  changeContentType,
  resetForm,
  changeName,
  setOpenForm,
} = actions

export default reducer
