import { createSlice } from '@reduxjs/toolkit'
import { updateType } from '../../../components/meetings/types/update/slice'

const initialState = {
  data: {
    name: '',
    metaPrompt: '',
    userPrompt: '',
    workspaceUuid: '',
  },
  openForm: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'meetingTypeUpdate',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateType.fulfilled, (state, action) => {
      state.openForm = false
    })
  },
  reducers: {
    formInit: (state, action) => {
      state.data.name = action.payload.name
      state.data.metaPrompt = action.payload.metaPrompt
      state.data.userPrompt = action.payload.userPrompt
      state.data.workspaceUuid = action.payload.workspaceUuid
    },
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changeMetaPrompt: (state, action) => {
      state.data.metaPrompt = action.payload.metaPrompt
    },
    changeUserPrompt: (state, action) => {
      state.data.userPrompt = action.payload.userPrompt
    },
    changeWorkspaceUuid: (state, action) => {
      state.data.workspaceUuid = action.payload.workspaceUuid
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload.openForm
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.meetingTypeUpdate
export const selectFormData = (state) => selectSlice(state).data
export const selectOpenForm = (state) => selectSlice(state).openForm
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const {
  changeRequestStatus,
  resetForm,
  changeName,
  changeMetaPrompt,
  changeUserPrompt,
  changeWorkspaceUuid,
  formInit,
  setOpenForm,
} = actions

export default reducer
