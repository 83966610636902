import { createSlice } from '@reduxjs/toolkit'
import { createNewTag } from '../../../../components/meetings/types/details/tags/create/slice'
import { failed, REQUEST_STATUSES } from '../../../reducer-helper'
import { updateTag } from '../../../../components/meetings/types/details/tags/update/slice'

const initialState = {
  data: {
    name: '',
    prompt: '',
    description: '',
  },
  openForm: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'meetingTypeTagUpdate',
  initialState,
  extraReducers(builder) {
    builder.addCase(updateTag.fulfilled, (state, action) => {
      state.openForm = null
    })
  },
  reducers: {
    formInit: (state, action) => {
      state.data.name = action.payload.name
      state.data.prompt = action.payload.prompt
      state.data.description = action.payload.description ?? ''
    },
    changeName: (state, action) => {
      state.data.name = action.payload.name
    },
    changePrompt: (state, action) => {
      state.data.prompt = action.payload.prompt
    },
    changeDescription: (state, action) => {
      state.data.description = action.payload.description
    },
    setOpenForm: (state, action) => {
      state.openForm = action.payload.openForm
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.meetingTypeTagUpdate
export const selectFormData = (state) => selectSlice(state).data

export const selectError = (state) => selectSlice(state).error

export const selectOpenForm = (state) => selectSlice(state).openForm

export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, resetForm, changeName, changePrompt, changeDescription, setOpenForm, formInit } =
  actions

export default reducer
