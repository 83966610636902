import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminUserAgent } from '../../../../api/adminUserAgent'

export const changePassword = createAsyncThunk(
  'admin-user/account/change-password',
  async ({ newPassword, oldPassword }, thunkApi) => {
    try {
      await AdminUserAgent.changePassword(newPassword, oldPassword)

      return { status: 'Ok' }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)
