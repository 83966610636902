import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingAgent } from '../../../api/meetingAgent'

export const getAllTypes = createAsyncThunk('meetings/types/get-all-types', async (undefined, thunkApi) => {
  try {
    const types = await MeetingAgent.getAllTypes()

    return { types }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
