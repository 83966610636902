import { Fragment, useEffect } from 'react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { getAllTemplates } from './slice'
import {
  reset,
  selectRequestStatus,
  selectAllTemplates,
  changeRequestStatus,
} from '../../../../reducers/types-and-templates/meeting-type-templates/template-list'
import Loader from '../../../common/loader/Loader'
import { PlusIcon } from '@heroicons/react/24/outline'
import CreateMeetingTypeTemplate from '../create-form-popup/CreateMeetingTypeTemplate'
import {
  selectOpenForm,
  setOpenForm,
} from '../../../../reducers/types-and-templates/meeting-type-templates/create-templates'

export default function TemplatesList() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const templates = useSelector(selectAllTemplates)

  const openNewTemplateForm = useSelector(selectOpenForm)
  const setOpenNewTemplateForm = (status) => {
    dispatch(setOpenForm({ openForm: status }))
  }

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchTemplates = dispatch(getAllTemplates())

    return () => {
      dispatch(reset())
      fetchTemplates.abort()
    }
  }, [])

  return (
    <div>
      {!templates && requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}
      {templates && requestStatus === REQUEST_STATUSES.DONE && (
        <>
          <div className={'px-8 pt-8'}>
            <div className={'flex flex-row justify-between items-center'}>
              <div className="flex flex-row items-center px-4 sm:px-0">
                <div>
                  <h3 className="text-base font-semibold leading-7 text-gray-900">Meeting type templates</h3>
                  <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">All meeting type templates</p>
                </div>
              </div>
              <div className={'flex flex-row gap-x-4 items-center'}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenNewTemplateForm(true)
                  }}
                  className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Create new template
                </button>
              </div>
            </div>
            <div className={'pt-8'}>
              {templates.length === 0 && <div className={'text-sm text-gray-700'}>There are no templates yet.</div>}
              {templates.length > 0 && (
                <div className="py-4 space-y-4">
                  {templates.map((template) => (
                    <NavLink
                      to={`/meeting-type-templates/${template.uuid}`}
                      key={`template-${template.uuid}`}
                      className={
                        'bg-gray-50 rounded-md p-6 flex flex-col justify-between hover:bg-indigo-50 transition-all'
                      }
                    >
                      <div>
                        <div className={'text-sm font-semibold text-gray-700'}>{template.name}</div>
                        <div className={'text-xs font-normal pt-2 text-gray-500'}>{template.description}</div>
                      </div>
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div className="z-30">
        <CreateMeetingTypeTemplate open={openNewTemplateForm} setOpen={setOpenNewTemplateForm} />
      </div>
    </div>
  )
}
