import React, { useEffect, useState } from 'react'
import MainLayout from '../common/protected/main-layout/MainLayout'
import { Link } from 'react-router-dom'
import { REQUEST_STATUSES } from '../../reducers/reducer-helper'
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid'
import { format } from 'date-fns'
import { ChevronRightIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { changeRequestStatus, selectAllUsers, selectRequestStatus, usersUnloaded } from '../../reducers/users/user-list'
import { getAllUsers } from './slice'
import Loader from '../common/loader/Loader'
import Statistic from './Statistic'

export default function Users() {
  const dispatch = useDispatch()

  const requestStatus = useSelector(selectRequestStatus)
  const users = useSelector(selectAllUsers)

  const [filteredUsers, setFilteredUsers] = useState([])
  const [userUuidForDelete, setUserUuidForDelete] = useState(null)

  useEffect(() => {
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    const fetchUsers = dispatch(getAllUsers())

    return () => {
      dispatch(usersUnloaded())
      fetchUsers.abort()
    }
  }, [])

  useEffect(() => {
    let filtered = []
    if (users && users.length > 0) {
      filtered = [...users]
      filtered.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))

      setFilteredUsers(filtered)
    }
  }, [users])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <MainLayout>
      <>
        {!users && requestStatus === REQUEST_STATUSES.PENDING && <Loader fullSize={true} />}

        {users && filteredUsers.length > 0 && (
          <>
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b bg-white border-black/5 px-4 sm:px-6 lg:px-8">
              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <form className="flex flex-1" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500"
                      aria-hidden="true"
                    />
                    <input
                      id="search-field"
                      className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-gray-900 focus:ring-0 sm:text-sm"
                      placeholder="Search..."
                      type="search"
                      name="search"
                    />
                  </div>
                </form>
              </div>
            </div>
            <Statistic />
            <div className="py-4">
              <div className="px-4 sm:px-6 lg:px-8">
                <ul role="list" className="divide-y divide-gray-100">
                  {filteredUsers.map((user) => (
                    <li key={user.uuid} className="relative w-full">
                      <Link to={`/users/${user.uuid}`}>
                        <div className="relative flex justify-between gap-x-6 py-5 ">
                          <div className="min-w-0">
                            <div className="flex items-start gap-x-3">
                              <p className="text-sm font-semibold leading-6 text-gray-900">{user.profile.fullName}</p>
                            </div>
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                              <p className="whitespace-nowrap">{user.email}</p>
                              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                <circle cx={1} cy={1} r={1} />
                              </svg>
                              <p className="whitespace-nowrap">{format(new Date(user.createdTime), 'MMMM do, yyyy')}</p>
                            </div>
                          </div>
                          <div className="flex flex-none items-center gap-x-4">
                            <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        )}
      </>
    </MainLayout>
  )
}
