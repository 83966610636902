import React, { Fragment } from 'react'
import { Dialog, Transition, Switch } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { changeRequestStatus } from '../../../../reducers/users/user-list'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeMetaPrompt,
  changeName,
  changeDescription,
  changeIsPopular,
  changeSystemTasks,
  changeUserPrompt,
  changeWorkspaceUuid,
  selectFormData,
} from '../../../../reducers/types-and-templates/meeting-type-templates/create-templates'
import { createNewTemplate } from './slice'

export default function CreateMeetingTypeTemplate({ open, setOpen }) {
  const dispatch = useDispatch()

  const formData = useSelector(selectFormData)

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }
  const changeDescriptionProcess = (event) => {
    event.preventDefault()
    dispatch(changeDescription({ description: event.target.value }))
  }
  const changeIsPopularProcess = () => {
    dispatch(changeIsPopular({ isPopular: !formData.isPopular }))
  }
  const changeSystemTasksProcess = (event) => {
    event.preventDefault()
    dispatch(changeSystemTasks({ systemTasks: event.target.value }))
  }
  const changeMetaPromptProcess = (event) => {
    event.preventDefault()
    dispatch(changeMetaPrompt({ metaPrompt: event.target.value }))
  }
  const changeUserPromptProcess = (event) => {
    event.preventDefault()
    dispatch(changeUserPrompt({ userPrompt: event.target.value }))
  }
  const changeWorkspaceUuidProcess = (event) => {
    dispatch(changeWorkspaceUuid({ workspaceUuid: event.target.value }))
  }

  const createTemplate = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      createNewTemplate({
        name: formData.name,
        description: formData.description,
        isPopular: formData.isPopular,
        systemTasks: formData.systemTasks,
        metaPrompt: formData.metaPrompt,
        userPrompt: formData.userPrompt,
        workspaceUuid: formData.workspaceUuid,
      }),
    )
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form onSubmit={createTemplate} className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Create new meeting type template
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Please fill the form below to create a new meeting type template
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Name
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              name="type-name"
                              id="type-name"
                              onChange={changeNameProcess}
                              value={formData.name}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Description
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <textarea
                              id="type-meta-prompt"
                              name="type-meta-prompt"
                              onChange={changeDescriptionProcess}
                              value={formData.description}
                              rows={6}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div></div>
                          <div>
                            <Switch.Group as="div" className="flex items-center">
                              <Switch
                                checked={formData.isPopular}
                                onChange={changeIsPopularProcess}
                                className={classNames(
                                  formData.isPopular ? 'bg-indigo-600' : 'bg-gray-200',
                                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    formData.isPopular ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                  )}
                                />
                              </Switch>
                              <Switch.Label as="span" className="ml-3 text-sm">
                                <span className="font-semibold text-gray-700">Mark as popular</span>
                              </Switch.Label>
                            </Switch.Group>
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              System tasks (Pure JSON)
                              <br />
                              <span className="text-gray-300 text-xs ">(Optional)</span>
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <textarea
                              id="system-tasks"
                              name="system-tasks"
                              onChange={changeSystemTasksProcess}
                              value={formData.systemTasks}
                              rows={6}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Meta prompt
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <textarea
                              id="type-meta-prompt"
                              name="type-meta-prompt"
                              onChange={changeMetaPromptProcess}
                              value={formData.metaPrompt}
                              rows={6}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-description"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              User prompt
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <textarea
                              id="type-user-prompt"
                              name="type-user-prompt"
                              onChange={changeUserPromptProcess}
                              value={formData.userPrompt}
                              rows={3}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Attached to workspace
                              <br />
                              <span className="text-gray-300 text-xs ">(Optional)</span>
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              name="type-workspace"
                              id="type-workspace"
                              onChange={changeWorkspaceUuidProcess}
                              value={formData.workspaceUuid}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
