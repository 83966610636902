import React, { useEffect } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowLeftIcon, Bars3Icon, CheckIcon, PlusIcon } from '@heroicons/react/24/outline'
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs'
import { selectTemplateDetails } from '../../../reducers/types-and-templates/meeting-type-templates/template-details'
import { selectTypeDetails } from '../../../reducers/types-and-templates/meeting-type/type-details'
import OutsideClickHandler from '../../common/outside-click-handler/OutsideClickHandler'
import { createNewTask, getAllTasks, reorderTask } from './slice'
import {
  selectFormData,
  changeName,
  changeRequestStatus as changeCreateTaskRequestStatus,
  selectOpenForm,
  setOpenForm,
} from '../../../reducers/types-and-templates/meeting-type-task/create-task'
import { REQUEST_STATUSES } from '../../../reducers/reducer-helper'
import { reorderingTasks, selectAllTasks } from '../../../reducers/types-and-templates/meeting-type-task/task-list'
import {
  reset as resetTaskList,
  changeRequestStatus,
} from '../../../reducers/types-and-templates/meeting-type-task/task-list'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default function MeetingTypeTasks() {
  const dispatch = useDispatch()

  const formData = useSelector(selectFormData)
  const template = useSelector(selectTemplateDetails)
  const type = useSelector(selectTypeDetails)
  const tasks = useSelector(selectAllTasks)
  const openNewTaskForm = useSelector(selectOpenForm)

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const setOpenNewTaskForm = (status) => {
    dispatch(setOpenForm({ openForm: status }))
  }

  const pages = [
    { name: 'Meeting type templates', href: '/meeting-type-templates', current: false },
    { name: template.name, href: `/meeting-type-templates/${template.uuid}`, current: false },
    { name: 'Meeting types', href: `/meeting-type-templates/${template.uuid}/meeting-types`, current: false },
    { name: type.name, href: `/meeting-type-templates/${template.uuid}/meeting-types/${type.uuid}`, current: false },
    { name: 'Tasks', href: '#', current: true },
  ]

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeName({ name: event.target.value }))
  }

  const createNewTaskProcess = (event) => {
    event.preventDefault()
    event.stopPropagation()

    dispatch(changeCreateTaskRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      createNewTask({
        typeUuid: type.uuid,
        name: formData.name,
      }),
    )
  }

  useEffect(() => {
    if (type.uuid && template.uuid) {
      dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))

      const fetchTasks = dispatch(getAllTasks({ typeUuid: type.uuid, orderingMap: type.tasksOrdering }))

      return () => {
        dispatch(resetTaskList())
        fetchTasks.abort()
      }
    }
  }, [type, template])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const reorderedTasks = reorder(tasks, result.source.index, result.destination.index)
    const reorderedTasksUuid = reorderedTasks.map((task) => task.uuid)

    dispatch(
      reorderTask({
        typeUuid: type.uuid,
        newOrdering: reorderedTasksUuid,
      }),
    )

    dispatch(reorderingTasks({ tasks: reorderedTasks }))
  }

  return (
    <div className={'w-full'}>
      <div className="sticky z-40 flex h-16 shrink-0 items-center gap-x-6 border-b bg-white border-black/5 px-4 sm:px-6 lg:px-8">
        <div>
          <Breadcrumbs pages={pages} />
        </div>
      </div>
      <div className={'px-8 pt-4'}>
        <div className={'flex flex-row justify-between items-center'}>
          <div className="flex flex-row items-center px-4 sm:px-0">
            <NavLink
              className={'p-4 rounded-md bg-gray-50 hover:bg-indigo-50 mr-6 cursor-pointer'}
              to={`/meeting-type-templates/${template.uuid}/meeting-types/${type.uuid}`}
            >
              <ArrowLeftIcon className={'w-5 h-5'} />
            </NavLink>
            <div>
              <h3 className="text-base font-semibold leading-7 text-gray-900">Meeting type AI tasks</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                All AI tasks related to the selected meeting type
              </p>
            </div>
          </div>
        </div>
        <div className={'mt-8 relative'}>
          <div className={'w-64 space-y-4 mb-32'}>
            <div>
              {tasks && (
                <div>
                  {tasks.length === 0 && <div>There are no tasks yet.</div>}
                  {tasks.length > 0 && (
                    <ul>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              {tasks.map((task, index) => (
                                <Draggable key={task.uuid} draggableId={task.uuid} index={index}>
                                  {(provided, snapshot) => (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={'w-full mb-2'}
                                    >
                                      <NavLink
                                        to={`/meeting-type-templates/${template.uuid}/meeting-types/${type.uuid}/tasks/${task.uuid}`}
                                        className={'block text-sm'}
                                      >
                                        {({ isActive }) => {
                                          return (
                                            <div
                                              className={classNames(
                                                'rounded-md w-full p-2 flex justify-start items-center hover:bg-indigo-50 transition-all',
                                                isActive ? 'bg-indigo-50' : 'bg-gray-50',
                                              )}
                                            >
                                              <Bars3Icon className={'w-4 h-4 mr-2 text-gray-300 cursor-grab'} />
                                              <div className={'flex w-full flex-row justify-between items-center'}>
                                                {task.name}
                                                {isActive && <ArrowLeftIcon className={'w-4 h-4'} />}
                                              </div>
                                            </div>
                                          )
                                        }}
                                      </NavLink>
                                    </li>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </ul>
                  )}
                </div>
              )}
            </div>
            {!openNewTaskForm && (
              <div>
                <button
                  type={'button'}
                  onClick={() => {
                    setOpenNewTaskForm(true)
                  }}
                  className="relative w-full flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <PlusIcon className={'-ml-0.5 h-5 w-5'} /> Create new task
                </button>
              </div>
            )}
            {openNewTaskForm && (
              <div>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setOpenNewTaskForm(false)
                  }}
                >
                  <form onSubmit={createNewTaskProcess} className={'relative'}>
                    <input
                      type={'text'}
                      placeholder={'Task name'}
                      autoFocus={true}
                      onChange={changeNameProcess}
                      value={formData.name}
                      className={'w-full rounded-md border-0 ring-1 text-sm text-gray-700'}
                      name={'task-name'}
                    />
                    <button
                      className={
                        'w-9 h-9 flex text-center items-center content-center justify-center rounded-md bg-gray-50 hover:bg-indigo-50 transition-all cursor-pointer absolute top-0 right-0'
                      }
                    >
                      <CheckIcon className={'w-4 h-4 text-gray-700'} />
                    </button>
                  </form>
                </OutsideClickHandler>
              </div>
            )}
          </div>
          <div className={'absolute top-0 right-0 left-64'}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}
