import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../../../reducers/admin-user/user'
import { Fragment } from 'react'
import {
  changeFullName,
  changeEmail,
  selectSettings,
  setSettings,
  changeEmailConfirmationPassword,
  selectChangeEmailRequestSent,
  changeRequestStatus,
  selectError,
  selectRequestStatus,
  resetForm,
} from '../../../../reducers/admin-user/user-general-settings-form'
import { REQUEST_STATUSES } from '../../../../reducers/reducer-helper'
import { updateProfileInformation } from './slice'
import ConfirmEmailForm from './ConfirmEmailForm'
import Loader from '../../../common/loader/Loader'
import NotificationPopup from '../../../common/notification-popup/NotificationPopup'

function UserGeneralSettings() {
  const changeEmailRequestSent = useSelector(selectChangeEmailRequestSent)

  const dispatch = useDispatch()

  const user = useSelector(selectUser)
  const formData = useSelector(selectSettings)
  const error = useSelector(selectError)
  const requestStatus = useSelector(selectRequestStatus)

  const changeNameProcess = (event) => {
    event.preventDefault()
    dispatch(changeFullName({ fullName: event.target.value }))
  }

  const changeEmailProcess = (event) => {
    event.preventDefault()
    dispatch(changeEmail({ email: event.target.value }))
  }

  const changeEmailConfirmationPasswordProcess = (event) => {
    event.preventDefault()
    dispatch(changeEmailConfirmationPassword({ emailConfirmationPassword: event.target.value }))
  }

  const saveSettings = (event) => {
    event.preventDefault()
    dispatch(changeRequestStatus({ status: REQUEST_STATUSES.PENDING }))
    dispatch(
      updateProfileInformation({
        fullName: formData.fullName,
        email: formData.email,
        password: formData.emailConfirmationPassword,
      }),
    )
  }

  useEffect(() => {
    dispatch(setSettings({ fullName: user.profile.fullName, email: user.email }))
  }, [user])

  useEffect(() => {
    return () => {
      dispatch(resetForm())
    }
  }, [])

  return (
    <>
      <div className="max-w-2xl">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">This is your main profile information</p>
          <div className="mt-2">{error && <NotificationPopup message={error.message} />}</div>
          <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <input
                  id="full-name"
                  name="full-name"
                  type="string"
                  value={formData.fullName}
                  onChange={(e) => {
                    changeNameProcess(e)
                  }}
                  placeholder="Full name"
                  autoComplete="full-name"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => {
                    changeEmailProcess(e)
                  }}
                  placeholder="Email address"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                {user.email !== formData.email && (
                  <input
                    id="email-confirm-password"
                    name="email-confirm-password"
                    type="password"
                    value={formData.emailConfirmationPassword}
                    onChange={(e) => {
                      changeEmailConfirmationPasswordProcess(e)
                    }}
                    placeholder="Your password"
                    autoComplete="your-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                )}
              </dd>
            </div>

            {(user.email !== formData.email || user.profile.fullName !== formData.fullName) && (
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-500 sm:w-64 sm:flex-none sm:pr-6"></dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <button
                    disabled={requestStatus === REQUEST_STATUSES.PENDING}
                    type="button"
                    onClick={saveSettings}
                    className={`flex items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  >
                    {requestStatus === REQUEST_STATUSES.PENDING && <Loader />}
                    <span>Save changes</span>
                  </button>
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
      {changeEmailRequestSent && <ConfirmEmailForm />}
    </>
  )
}

export default memo(UserGeneralSettings)
