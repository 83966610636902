import React from 'react'
import { Route } from 'react-router-dom'
import SignIn from '../admin-user/sign-in/SignIn'
import ForgotPassword from '../admin-user/forgot-password/ForgotPassword'
import ProtectedRoute from '../common/ProtectedRoute'
import Dashboard from '../dashboard/Dashboard'
import UserSettings from '../admin-user/settings/UserSettings'
import UserGeneralSettings from '../admin-user/settings/general/UserGeneralSettings'
import SecuritySettings from '../admin-user/settings/security/SecuritySettings'
import Meetings from '../meetings/Meetings'
import MeetingList from '../meetings/list/MeetingList'
import MeetingTypes from '../meetings/types/MeetingTypes'
import Users from '../users/Users'
import TypeDetails from '../meetings/types/details/TypeDetails'
import TemplatesList from '../types-and-templates/template/list/TemplatesList'
import TypesAndTemplates from '../types-and-templates/TypesAndTemplates'
import TemplateDetails from '../types-and-templates/template/details/TemplateDetails'
import MeetingTypeList from '../types-and-templates/type/list/MeetingTypeList'
import MeetingTypeDetails from '../types-and-templates/type/details/TypeDetails'
import MeetingTypeTasks from '../types-and-templates/tasks/MeetingTypeTasks'
import TaskDetails from '../types-and-templates/tasks/details/TaskDetails'
import Root from '../root/Root'

export default function getRoutes() {
  return (
    <Route path={'/'} element={<Root />}>
      <Route exact path="/sign-in" element={<SignIn />} />
      <Route exact path="/forgot-password" element={<ForgotPassword />} />
      <Route
        exact
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/meeting-type-templates"
        element={
          <ProtectedRoute>
            <TypesAndTemplates />
          </ProtectedRoute>
        }
      >
        <Route exact path="" element={<TemplatesList />} />
        <Route exact path=":templateUuid" element={<TemplateDetails />} />
        <Route exact path=":templateUuid/meeting-types" element={<MeetingTypeList />} />
        <Route exact path=":templateUuid/meeting-types/:typeUuid" element={<MeetingTypeDetails />} />
        <Route exact path=":templateUuid/meeting-types/:typeUuid/tasks" element={<MeetingTypeTasks />}>
          <Route exact path=":taskUuid" element={<TaskDetails />} />
        </Route>
      </Route>
      <Route
        exact
        path="/meetings"
        element={
          <ProtectedRoute>
            <Meetings />
          </ProtectedRoute>
        }
      >
        <Route exact path="" element={<MeetingList />} />
        <Route exact path="types" element={<MeetingTypes />} />
        <Route exact path="types/:meetingTypeUuid" element={<TypeDetails />} />
      </Route>
      <Route
        exact
        path="/users"
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/settings"
        element={
          <ProtectedRoute>
            <UserSettings />
          </ProtectedRoute>
        }
      >
        <Route exact path="general" element={<UserGeneralSettings />} />
        <Route exact path="security" element={<SecuritySettings />} />
      </Route>
    </Route>
  )
}
