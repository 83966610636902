import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingAgent } from '../../../../api/meetingAgent'

export const createNewType = createAsyncThunk(
  'meetings/types/create',
  async ({ name, metaPrompt, userPrompt, workspaceUuid }, thunkApi) => {
    try {
      const type = await MeetingAgent.createNewMeetingType(name, metaPrompt, userPrompt, workspaceUuid)

      return { type }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
