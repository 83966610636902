import { createSlice } from '@reduxjs/toolkit'
import { updateProfileInformation, confirmNewEmail } from '../../components/admin-user/settings/general/slice'
import { changePassword as changePasswordRequest } from '../../components/admin-user/settings/security/slice'
import { failed, resetErrors, resetStatus } from '../reducer-helper'

const initialState = {
  settings: {
    fullName: '',
    email: '',
    emailConfirmationPassword: '',
    password: '',
    currentPassword: '',
    confirmNewEmailToken: '',
  },
  changeEmailRequestSent: false,
  formLoaded: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'adminUserGeneralSettingsForm',
  initialState,
  reducers: {
    changeFullName: (state, action) => {
      state.settings.fullName = action.payload.fullName
    },
    changeEmail: (state, action) => {
      state.settings.email = action.payload.email
    },
    changePassword: (state, action) => {
      state.settings.password = action.payload.password
    },
    changeEmailConfirmationPassword: (state, action) => {
      state.settings.emailConfirmationPassword = action.payload.emailConfirmationPassword
    },
    changeConfirmNewEmailToken: (state, action) => {
      state.settings.confirmNewEmailToken = action.payload.token
    },
    changeCurrentPassword: (state, action) => {
      state.settings.currentPassword = action.payload.currentPassword
    },
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
    resetChangeEmailRequest: (state, action) => {
      state.changeEmailRequestSent = false
    },
    setSettings: (state, action) => {
      state.settings.fullName = action.payload.fullName
      state.settings.email = action.payload.email

      state.formLoaded = true
    },
    resetForm: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(updateProfileInformation.fulfilled, (state, action) => {
      state.changeEmailRequestSent = action.payload.emailChanged
      resetStatus(state)
      resetErrors(state)
    })
    builder.addCase(updateProfileInformation.rejected, failed)
    builder.addCase(changePasswordRequest.rejected, failed)
    builder.addCase(confirmNewEmail.fulfilled, (state, action) => {
      resetStatus(state)
      resetErrors(state)
      state.changeEmailRequestSent = false
      state.settings.emailConfirmationPassword = ''
    })
    builder.addCase(confirmNewEmail.rejected, failed)
  },
})

const selectUserGeneralSettingsFormSlice = (state) => state.adminUserGeneralSettingsForm
export const selectSettings = (state) => selectUserGeneralSettingsFormSlice(state).settings
export const isSettingsFormLoaded = (state) => selectUserGeneralSettingsFormSlice(state).formLoaded

export const selectChangeEmailRequestSent = (state) => selectUserGeneralSettingsFormSlice(state).changeEmailRequestSent
export const selectError = (state) => selectUserGeneralSettingsFormSlice(state).error
export const selectRequestStatus = (state) => selectUserGeneralSettingsFormSlice(state).status

const { reducer, actions } = slice

export const {
  changeFullName,
  changeEmail,
  changePassword,
  changeEmailConfirmationPassword,
  changeCurrentPassword,
  setSettings,
  resetForm,
  resetChangeEmailRequest,
  changeRequestStatus,
  changeConfirmNewEmailToken,
} = actions

export default reducer
