import { requests } from './agent'

export const MeetingTypeAgent = {
  getAllTemplateMeetingTypes: (templateUuid) => {
    return requests.get(`/1_0/admin/meeting-type-templates/${templateUuid}/meeting-types/list`)
  },

  createNewMeetingTypeV2: (name, description, templateUuid, context, variables, workspaceUuid) => {
    return requests.post(`/1_0/admin/meeting-type-templates/${templateUuid}/meeting-types/create`, {
      name,
      description,
      context,
      variables,
      workspaceUuid,
    })
  },

  getMeetingTypeDetails: (meetingTypeUuid) => {
    return requests.get(`/1_0/admin/meeting-types/${meetingTypeUuid}`)
  },

  updateMeetingType: (meetingTypeUuid, templateUuid, name, description, context, variables, workspaceUuid) => {
    return requests.post(`/1_0/admin/meeting-types/${meetingTypeUuid}/update`, {
      name,
      templateUuid,
      description,
      context,
      variables,
      workspaceUuid,
    })
  },

  deleteMeetingType: (meetingTypeUuid) => {
    return requests.post(`/1_0/admin/meeting-types/${meetingTypeUuid}/delete`)
  },
}
