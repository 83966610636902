import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingAgent } from '../../../../../../api/meetingAgent'

export const updateTag = createAsyncThunk(
  'meetings/types/tags/update',
  async ({ meetingTypeUuid, tagUuid, name, description, prompt }, thunkApi) => {
    try {
      const tag = await MeetingAgent.updateMeetingTypeTag(meetingTypeUuid, tagUuid, name, description, prompt)

      return { tag }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
