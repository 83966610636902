import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { getUsersStatistic } from '../../components/users/slice'

const initialState = {
  statistic: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'userStatistic',
  initialState,
  extraReducers(builder) {
    builder.addCase(getUsersStatistic.fulfilled, (state, action) => {
      state.statistic = action.payload.statistic
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(getUsersStatistic.rejected, failed)
  },
  reducers: {
    statisticUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.userStatistic
export const selectStatistic = (state) => selectSlice(state).statistic

export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, statisticUnloaded } = actions

export default reducer
