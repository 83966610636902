import React, { memo } from 'react'

function NotificationPopup({ message }) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="text-sm text-red-700">
          <span>{message}</span>
        </div>
      </div>
    </div>
  )
}

export default memo(NotificationPopup)
