import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { getAllUsers } from '../../components/users/slice'

const initialState = {
  users: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'userList',
  initialState,
  extraReducers(builder) {
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.users = action.payload.users
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(getAllUsers.rejected, failed)
  },
  reducers: {
    usersUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.userList
export const selectAllUsers = (state) => selectSlice(state).users

export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, usersUnloaded } = actions

export default reducer
