import { createSlice } from '@reduxjs/toolkit'
import { initApplication } from '../components/app/slice'
import { ComponentRequestStatus } from '../constants'
import { signIn } from '../components/admin-user/sign-in/slice'
import { logout } from '../components/common/protected/main-layout/slice'
import { deleteType } from '../components/meetings/types/details/slice'
import { deleteTemplate } from '../components/types-and-templates/template/confirm-delete-popup/slice'
import { deleteMeetingType } from '../components/types-and-templates/type/confirm-delete-popup/slice'

const initialState = {
  appLoaded: false,
  status: ComponentRequestStatus.IDLE,
  redirectTo: undefined,
  error: null,
}

function success(state) {
  state.appLoaded = true
  state.status = ComponentRequestStatus.SUCCESS
  delete state.errors
}

function failed(state) {
  state.appLoaded = true
  state.status = ComponentRequestStatus.FAILURE
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    clearRedirect(state) {
      delete state.redirectTo
    },
  },
  extraReducers(builder) {
    builder.addCase(initApplication.fulfilled, success)

    builder.addCase(initApplication.rejected, failed)

    builder.addCase(signIn.fulfilled, (state, action) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(deleteType.fulfilled, (state, action) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(deleteTemplate.fulfilled, (state, action) => {
      state.redirectTo = '/meeting-type-templates'
    })

    builder.addCase(deleteMeetingType.fulfilled, (state, action) => {
      state.redirectTo = `/meeting-type-templates/${action.payload.templateUuid}/meeting-types`
    })

    builder.addCase(logout.fulfilled, (state, action) => {
      state.redirectTo = '/sign-in'
    })
  },
})

const { reducer, actions } = commonSlice

export const { clearRedirect } = actions

export default reducer
