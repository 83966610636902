import { createSlice } from '@reduxjs/toolkit'
import { getAllTemplates } from '../../../components/types-and-templates/template/list/slice'
import { failed, REQUEST_STATUSES } from '../../reducer-helper'
import { createNewTemplate } from '../../../components/types-and-templates/template/create-form-popup/slice'

const initialState = {
  templates: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'meetingTypeTemplatesList',
  initialState,
  extraReducers(builder) {
    builder.addCase(getAllTemplates.fulfilled, (state, action) => {
      state.templates = action.payload.templates
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(getAllTemplates.rejected, failed)
    builder.addCase(createNewTemplate.fulfilled, (state, action) => {
      state.templates.push(action.payload.template)
    })
    builder.addCase(createNewTemplate.rejected, failed)
  },
  reducers: {
    reset: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.meetingTypeTemplatesList
export const selectAllTemplates = (state) => selectSlice(state).templates

export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, reset } = actions

export default reducer
