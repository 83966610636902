import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectStep, resetForm } from '../../../reducers/admin-user/forgot-password-form'
import EmailForm from './EmailForm'
import ConfirmEmailForm from './ConfirmEmailForm'
import ChangePasswordForm from './ChangePasswordForm'
import Success from './Success'
import SingleFormWrapper from '../../common/singl-form-wrapper/SingleFormWrapper'

function ForgotPassword() {
  const dispatch = useDispatch()
  const step = useSelector(selectStep)

  useEffect(() => {
    return () => {
      dispatch(resetForm())
    }
  }, [])

  return (
    <SingleFormWrapper>
      {step === 1 && <EmailForm />}
      {step === 2 && <ConfirmEmailForm />}
      {step === 3 && <ChangePasswordForm />}
      {step === 4 && <Success />}
    </SingleFormWrapper>
  )
}

export default memo(ForgotPassword)
