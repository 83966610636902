import axios from 'axios'
import { API_URL } from '../configs'

let apiToken = null

const agent = async (url, data = {}, method = 'GET', headers = {}) => {
  if (apiToken) {
    headers['admin-api-token'] = apiToken
  }
  const response = await axios({ method, url: `${API_URL}${url}`, data, headers })

  let result
  if (response.data.statusCode === 200 || response.data.statusCode === 201) {
    result = response.data.data
  } else {
    result = {
      error: {
        statusCode: response.statusCode,
        internalCode: response.errorCode,
        message: response.message,
      },
    }

    throw result
  }

  return result
}

export const requests = {
  get: (url, headers) => agent(url, {}, 'GET', headers),
  post: (url, body, headers) => agent(url, body, 'POST', headers),
  put: (url, body, headers) => agent(url, body, 'PUT', headers),
  delete: (url, headers) => agent(url, {}, 'DELETE', headers),
}

export default {
  setApiToken: (_apiToken) => {
    apiToken = _apiToken
  },
}
