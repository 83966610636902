import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../reducer-helper'
import { deleteMeeting, getAllMeetings } from '../../components/dashboard/slice'

const initialState = {
  meetings: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'meetingList',
  initialState,
  extraReducers(builder) {
    builder.addCase(getAllMeetings.fulfilled, (state, action) => {
      state.meetings = action.payload.meetings
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(getAllMeetings.rejected, failed)
    builder.addCase(deleteMeeting.fulfilled, (state, actions) => {
      const deleteMeetingIndex = state.meetings.findIndex((meeting) => meeting.uuid === actions.payload.meetingUuid)
      state.meetings.splice(deleteMeetingIndex, 1)
    })
    builder.addCase(deleteMeeting.rejected, failed)
  },
  reducers: {
    meetingsUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.meetingList
export const selectAllMeetings = (state) => selectSlice(state).meetings

export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, meetingsUnloaded } = actions

export default reducer
