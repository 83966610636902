import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingAgent } from '../../../../api/meetingAgent'

export const updateType = createAsyncThunk(
  'meetings/types/update',
  async ({ typeUuid, name, metaPrompt, userPrompt, workspaceUuid }, thunkApi) => {
    try {
      const type = await MeetingAgent.updateMeetingType(typeUuid, name, metaPrompt, userPrompt, workspaceUuid)

      return { type }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
