import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminUserAgent } from '../../../../api/adminUserAgent'

export const updateProfileInformation = createAsyncThunk(
  'admin-user/update',
  async ({ fullName, email, password }, thunkApi) => {
    try {
      await AdminUserAgent.update(fullName, email, password)

      return { fullName, emailChanged: !!password }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const confirmNewEmail = createAsyncThunk(
  'admin-user/account/confirm-new-email',
  async ({ token, email }, thunkApi) => {
    try {
      await AdminUserAgent.confirmNewEmail(token)

      return { email }
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  },
)
