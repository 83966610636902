import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingAgent } from '../../../../api/meetingAgent'

export const getTypeDetails = createAsyncThunk('meetings/types/get-all-types', async ({ uuid }, thunkApi) => {
  try {
    const type = await MeetingAgent.getTypeDetails(uuid)

    return { type }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
export const deleteType = createAsyncThunk('meetings/types/delete', async ({ uuid }, thunkApi) => {
  try {
    await MeetingAgent.deleteType(uuid)

    return { deletedTypeUuid: uuid }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
