import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../../reducer-helper'
import { getTypeDetails } from '../../../components/meetings/types/details/slice'
import { updateType } from '../../../components/meetings/types/update/slice'
import { createNewTag } from '../../../components/meetings/types/details/tags/create/slice'
import { updateTag } from '../../../components/meetings/types/details/tags/update/slice'

const initialState = {
  data: {
    uuid: null,
    name: null,
    metaPrompt: null,
    tags: null,
    userPrompt: null,
    accountUuid: null,
  },
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'meetingTypeDetails',
  initialState,
  extraReducers(builder) {
    builder.addCase(getTypeDetails.fulfilled, (state, action) => {
      state.data = action.payload.type
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(createNewTag.fulfilled, (state, action) => {
      state.data.tags.push(action.payload.tag)
    })
    builder.addCase(updateTag.fulfilled, (state, action) => {
      const tagIndex = state.data.tags.findIndex((tag) => tag.uuid === action.payload.tag.uuid)

      state.data.tags[tagIndex] = action.payload.tag
    })
    builder.addCase(getTypeDetails.rejected, failed)
    builder.addCase(updateType.fulfilled, (state, action) => {
      state.data = action.payload.type
    })
  },
  reducers: {
    typeUnloaded: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.meetingTypeDetails
export const selectTypeDetails = (state) => selectSlice(state).data

export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, typeUnloaded } = actions

export default reducer
