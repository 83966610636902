import { createSlice } from '@reduxjs/toolkit'
import { failed, REQUEST_STATUSES } from '../../reducer-helper'
import { getAllTypes } from '../../../components/types-and-templates/type/list/slice'
import { createNewMeetingType } from '../../../components/types-and-templates/type/create-form-popup/slice'

const initialState = {
  types: null,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'meetingTypeListV2',
  initialState,
  extraReducers(builder) {
    builder.addCase(getAllTypes.fulfilled, (state, action) => {
      state.types = action.payload.types
      state.status = REQUEST_STATUSES.DONE
    })
    builder.addCase(getAllTypes.rejected, failed)
    builder.addCase(createNewMeetingType.fulfilled, (state, action) => {
      state.types.push(action.payload.type)
    })
    builder.addCase(createNewMeetingType.rejected, failed)
  },
  reducers: {
    reset: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.meetingTypeListV2
export const selectAllTypes = (state) => selectSlice(state).types
export const selectRequestStatus = (state) => selectSlice(state).status

const { reducer, actions } = slice

export const { changeRequestStatus, reset } = actions

export default reducer
