import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    sectionIndex: null,
    rowIndex: null,
    colIndex: null,
    colName: null,
    content: '',
  },
  openForm: false,
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'updateCellContent',
  initialState,
  reducers: {
    formInit: (state, action) => {
      state.data.content = action.payload.content
    },
    changeContent: (state, action) => {
      state.data.content = action.payload.content
    },
    setOpenForm: (state, action) => {
      state.data.sectionIndex = action.payload.sectionIndex
      state.data.rowIndex = action.payload.rowIndex
      state.data.colIndex = action.payload.colIndex
      state.data.colName = action.payload.colName
      state.data.content = action.payload.content
      state.openForm = action.payload.openForm
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.updateCellContent
export const selectFormData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
export const selectOpenForm = (state) => selectSlice(state).openForm

const { reducer, actions } = slice

export const { changeRequestStatus, formInit, resetForm, changeContent, setOpenForm } = actions

export default reducer
