import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    sections: [],
  },
  error: null,
  status: null,
}

const slice = createSlice({
  name: 'taskConstructor',
  initialState,
  reducers: {
    initForm: (state, action) => {
      state.data.sections = action.payload.sections
    },
    addSection: (state, action) => {
      state.data.sections.push(action.payload.section)
    },
    deleteSection: (state, action) => {
      state.data.sections.splice(action.payload.indexForDelete, 1)
    },
    changeTextSection: (state, action) => {
      state.data.sections[action.payload.index].task = action.payload.task
    },
    changeListSection: (state, action) => {
      state.data.sections[action.payload.index].task = action.payload.task
    },
    addColumn: (state, action) => {
      if (state.data.sections[action.payload.index].contentType === 'table') {
        state.data.sections[action.payload.index].cols.push(action.payload.column)
        state.data.sections[action.payload.index].rows.forEach((row) => {
          const colStructure = {
            value: '',
            contentType: action.payload.column.contentType,
          }
          if (action.payload.column.contentType === 'ai-task') {
            colStructure.responseType = action.payload.column.responseType
          }

          row.push(colStructure)
        })
      }
    },
    updateColumn: (state, action) => {
      if (state.data.sections[action.payload.sectionIndex].contentType === 'table') {
        state.data.sections[action.payload.sectionIndex].cols[action.payload.colIndex] = action.payload.column
      }
    },
    addRow: (state, action) => {
      if (state.data.sections[action.payload.index].contentType === 'table') {
        state.data.sections[action.payload.index].rows.push(action.payload.row)
      }
    },
    updateCellContent: (state, action) => {
      if (state.data.sections[action.payload.sectionIndex].contentType === 'table') {
        state.data.sections[action.payload.sectionIndex].rows[action.payload.rowIndex][action.payload.colIndex].value =
          action.payload.value
      }
    },
    deleteColumn: (state, action) => {
      if (state.data.sections[action.payload.sectionIndex].contentType === 'table') {
        state.data.sections[action.payload.sectionIndex].cols.splice(action.payload.indexForDelete, 1)
        state.data.sections[action.payload.sectionIndex].rows.forEach((row) => {
          row.splice(action.payload.indexForDelete, 1)
        })
      }
    },
    deleteRow: (state, action) => {
      if (state.data.sections[action.payload.sectionIndex].contentType === 'table') {
        state.data.sections[action.payload.sectionIndex].rows.splice(action.payload.indexForDelete, 1)
      }
    },
    resetForm: () => initialState,
    changeRequestStatus: (state, action) => {
      state.status = action.payload.status
    },
  },
})

const selectSlice = (state) => state.taskConstructor
export const selectData = (state) => selectSlice(state).data
export const selectError = (state) => selectSlice(state).error
export const selectRequestStatus = (state) => selectSlice(state).status
const { reducer, actions } = slice

export const {
  changeRequestStatus,
  resetForm,
  initForm,
  addColumn,
  updateColumn,
  deleteSection,
  deleteColumn,
  deleteRow,
  addRow,
  changeTextSection,
  changeListSection,
  addSection,
  updateCellContent,
} = actions

export default reducer
