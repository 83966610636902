import { requests } from './agent'

export const MeetingAgent = {
  getAllMeetings: () => {
    return requests.get('/1_0/admin/meetings/list')
  },

  getAllTypes: () => {
    return requests.get('/1_0/admin/meetings/types/list')
  },

  getTypeDetails: (meetingTypeUuid) => {
    return requests.get(`/1_0/admin/meetings/types/${meetingTypeUuid}`)
  },

  getMeetingDetails: (meetingUuid) => {
    return requests.get(`/1_0/admin/meetings/${meetingUuid}`)
  },

  createNewMeetingType: (name, metaPrompt, userPrompt, workspaceUuid) => {
    return requests.post('/1_0/admin/meetings/types/create', {
      name,
      metaPrompt,
      userPrompt,
      workspaceUuid,
    })
  },

  updateMeetingType: (typeUuid, name, metaPrompt, userPrompt, workspaceUuid) => {
    return requests.post(`/1_0/admin/meetings/types/${typeUuid}/update`, {
      name,
      metaPrompt,
      userPrompt,
      workspaceUuid,
    })
  },

  createNewMeetingTypeTag: (meetingTypeUuid, name, description, prompt) => {
    return requests.post(`/1_0/admin/meetings/types/${meetingTypeUuid}/tags/create`, {
      name,
      description,
      prompt,
    })
  },

  updateMeetingTypeTag: (meetingTypeUuid, tagUuid, name, description, prompt) => {
    return requests.post(`/1_0/admin/meetings/types/${meetingTypeUuid}/tags/${tagUuid}/update`, {
      name,
      description,
      prompt,
    })
  },

  deleteMeeting: (meetingUuid) => {
    return requests.post(`/1_0/admin/meetings/${meetingUuid}/delete`)
  },

  deleteType: (typeUuid) => {
    return requests.post(`/1_0/admin/meetings/types/${typeUuid}/delete`)
  },
}
