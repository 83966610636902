import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingAgent } from '../../api/meetingAgent'

export const getAllMeetings = createAsyncThunk('meeting-list/get-all-meetings', async (undefined, thunkApi) => {
  try {
    const meetings = await MeetingAgent.getAllMeetings()

    return { meetings }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
export const deleteMeeting = createAsyncThunk('meeting/delete-meetings', async ({ meetingUuid }, thunkApi) => {
  try {
    await MeetingAgent.deleteMeeting(meetingUuid)

    return { meetingUuid }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
