import { createAsyncThunk } from '@reduxjs/toolkit'
import { MeetingTypeAgent } from '../../../../api/meetingTypeAgent'

export const updateMeetingType = createAsyncThunk(
  'meeting-types-v2/update-meeting-type',
  async ({ uuid, name, description, context, variables, templateUuid, workspaceUuid }, thunkApi) => {
    try {
      const type = await MeetingTypeAgent.updateMeetingType(
        uuid,
        templateUuid,
        name,
        description,
        context,
        variables,
        workspaceUuid,
      )

      return { type }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
