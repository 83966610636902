import { configureStore } from '@reduxjs/toolkit'
import { localStorageMiddleware } from './middleware'
import commonReducer from '../reducers/common'
import userReducer from '../reducers/admin-user/user'
import signInFormReducer from '../reducers/admin-user/sign-in-form'
import forgotPasswordFormReducer from '../reducers/admin-user/forgot-password-form'
import userGeneralSettingsFormReducer from '../reducers/admin-user/user-general-settings-form'
import meetingListReducer from '../reducers/meetings/meeting-list'
import meetingTypeListReducer from '../reducers/meetings/types/type-list'
import meetingTypeCreateReducer from '../reducers/meetings/types/create'
import meetingTypeUpdateReducer from '../reducers/meetings/types/update'
import meetingTypeDetailsReducer from '../reducers/meetings/types/details'
import meetingTypeTagCreateReducer from '../reducers/meetings/types/tags/create'
import meetingTypeTagUpdateReducer from '../reducers/meetings/types/tags/update'
import userListReducer from '../reducers/users/user-list'
import userStatisticReducer from '../reducers/users/user-statistic'
import meetingTypeTemplatesListReducer from '../reducers/types-and-templates/meeting-type-templates/template-list'
import createMeetingTypeTemplateReducer from '../reducers/types-and-templates/meeting-type-templates/create-templates'
import updateMeetingTypeTemplateReducer from '../reducers/types-and-templates/meeting-type-templates/update-templates'
import meetingTemplateDetailsReducer from '../reducers/types-and-templates/meeting-type-templates/template-details'
import meetingTypeListV2Reducer from '../reducers/types-and-templates/meeting-type/type-list'
import createMeetingTypeReducer from '../reducers/types-and-templates/meeting-type/create-type'
import updateMeetingTypeReducer from '../reducers/types-and-templates/meeting-type/update-type'
import meetingTypeDetailsV2Reducer from '../reducers/types-and-templates/meeting-type/type-details'
import createMeetingTypeTaskReducer from '../reducers/types-and-templates/meeting-type-task/create-task'
import meetingTypeTaskListReducer from '../reducers/types-and-templates/meeting-type-task/task-list'
import meetingTypeTaskDetailsReducer from '../reducers/types-and-templates/meeting-type-task/task-details'
import renameMeetingTypeTaskReducer from '../reducers/types-and-templates/meeting-type-task/rename-task'
import updateMeetingTypeTaskDescriptionReducer from '../reducers/types-and-templates/meeting-type-task/update-task-description'
import taskConstructorReducer from '../reducers/types-and-templates/meeting-type-task/constructor'
import addColumnReducer from '../reducers/types-and-templates/meeting-type-task/add-column'
import updateCellContentReducer from '../reducers/types-and-templates/meeting-type-task/update-cell-content'
import updateColumnReducer from '../reducers/types-and-templates/meeting-type-task/update-column'

export function makeStore(preloadedState) {
  return configureStore({
    reducer: {
      common: commonReducer,
      // Admin user
      adminUser: userReducer,
      adminSignInForm: signInFormReducer,
      adminForgotPasswordForm: forgotPasswordFormReducer,
      adminUserGeneralSettingsForm: userGeneralSettingsFormReducer,
      // Meeting
      meetingList: meetingListReducer,
      meetingTypeList: meetingTypeListReducer,
      meetingTypeCreate: meetingTypeCreateReducer,
      meetingTypeUpdate: meetingTypeUpdateReducer,
      meetingTypeDetails: meetingTypeDetailsReducer,
      meetingTypeTagCreate: meetingTypeTagCreateReducer,
      meetingTypeTagUpdate: meetingTypeTagUpdateReducer,
      meetingTypeTemplatesList: meetingTypeTemplatesListReducer,
      createMeetingTypeTemplate: createMeetingTypeTemplateReducer,
      updateMeetingTypeTemplate: updateMeetingTypeTemplateReducer,
      meetingTemplateDetails: meetingTemplateDetailsReducer,
      meetingTypeListV2: meetingTypeListV2Reducer,
      createMeetingType: createMeetingTypeReducer,
      updateMeetingType: updateMeetingTypeReducer,
      meetingTypeDetailsV2: meetingTypeDetailsV2Reducer,
      createMeetingTypeTask: createMeetingTypeTaskReducer,
      meetingTypeTaskList: meetingTypeTaskListReducer,
      meetingTypeTaskDetails: meetingTypeTaskDetailsReducer,
      renameMeetingTypeTask: renameMeetingTypeTaskReducer,
      updateMeetingTypeTaskDescription: updateMeetingTypeTaskDescriptionReducer,
      taskConstructor: taskConstructorReducer,
      addColumn: addColumnReducer,
      updateCellContent: updateCellContentReducer,
      updateColumn: updateColumnReducer,
      // Users
      userList: userListReducer,
      userStatistic: userStatisticReducer,
    },
    devTools: true,
    preloadedState,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), localStorageMiddleware],
  })
}

const store = makeStore()

export default store
