import { createAsyncThunk } from '@reduxjs/toolkit'
import { AdminUserAgent } from '../../../../api/adminUserAgent'

export const logout = createAsyncThunk('admin-admin-user/log-out', async (undefined, thunkApi) => {
  try {
    const response = await AdminUserAgent.logOut()

    return response
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
